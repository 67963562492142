/* eslint-disable react/prop-types */
import { Trade, TradeType, CurrencyAmount, WETH, Token, ChainId } from '@safemoon/sdk'
import React, { useContext, useMemo } from 'react'
import { ThemeContext } from 'styled-components'
import { Field } from '../../state/swap/actions'
import { useGasPrice, useUserSlippageTolerance } from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../utils/prices'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { SectionBreak } from './styleds'
import SwapRoute from './SwapRoute'
import { useTranslation } from 'react-i18next'
import Divider from '../Divider'
import { useActiveWeb3React } from '../../hooks'
import getTokenSymbol, { getNativeSymbol } from '../../utils/getTokenSymbol'
import Card from '../Card'
import TradePrice from './TradePrice'
import { formatStringToNumber, formatStringToPrice, isStableCoin } from '../../utils'

function TradeSummary({
  trade,
  allowedSlippage,
  ethFee,
  networkFee,
  nativePriceUsd,
  priceUsd
}: {
  trade: Trade
  allowedSlippage: number
  ethFee: string | undefined
  networkFee?: any
  nativePriceUsd: number
  priceUsd?: any
}) {
  const theme = useContext(ThemeContext)
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()

  const gasPrice = +useGasPrice() / 10 ** 9

  const isTradeRouter = true

  const minimumReceivedUsd = useMemo(() => {
    const symbol = isExactIn ? trade.outputAmount.currency?.symbol : trade.inputAmount.currency?.symbol
    let address = isExactIn
      ? (trade.outputAmount.currency as Token)?.address
      : (trade.inputAmount.currency as Token)?.address
    if (symbol === 'ETH' && !address) {
      address = WETH[chainId as ChainId]?.address
    } else if (symbol === 'ETH' && address && chainId === 56) {
      address = WETH[1]?.address
    }

    return slippageAdjustedAmounts && slippageAdjustedAmounts[isExactIn ? Field.OUTPUT : Field.INPUT]
      ? +(slippageAdjustedAmounts[isExactIn ? Field.OUTPUT : Field.INPUT]?.toFixed() || 0) *
          priceUsd[address?.toLowerCase()]
      : 0
  }, [isExactIn, slippageAdjustedAmounts, priceUsd, chainId])

  return (
    <>
      <AutoColumn gap={'sm'} style={{ padding: '0' }}>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel} style={{ whiteSpace: 'nowrap' }}>
              {isExactIn ? t('minimumReceived') : t('maximumSold')}
            </TYPE.black>
            <QuestionHelper text={t('yourTXWill3')} size={'xs'} />
          </RowFixed>
          <TYPE.black color={theme.secondary2} fontSize={14} fontWeight={400} flex={1} textAlign="right">
            {isExactIn
              ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${getTokenSymbol(
                  trade.outputAmount.currency,
                  chainId
                )}` ?? '-'
              : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${getTokenSymbol(
                  trade.inputAmount.currency,
                  chainId
                )}` ?? '-'}{' '}
            {!isStableCoin(isExactIn ? trade.outputAmount.currency?.symbol : trade.inputAmount.currency?.symbol) &&
              `($${formatStringToPrice(minimumReceivedUsd)})`}
          </TYPE.black>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel}>
              {t('priceImpact')}
            </TYPE.black>
            <QuestionHelper text={t('theDifference')} size={'xs'} />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>
        {!isTradeRouter && (
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel}>
                {t('liquidityProvider')}
              </TYPE.black>
              <QuestionHelper text={t('aPortion')} size={'xs'} />
            </RowFixed>
            <TYPE.black fontSize={14} color={theme.yellow1} fontWeight={400}>
              {realizedLPFee
                ? `${realizedLPFee.toSignificant(4)} ${getTokenSymbol(trade.inputAmount.currency, chainId)}`
                : '-'}
            </TYPE.black>
          </RowBetween>
        )}

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel} sx={{ whiteSpace: 'nowrap' }}>
              Network Fee ({gasPrice} GWEI)
            </TYPE.black>
            <QuestionHelper size={'xs'} text="This is the gas fee charged by the blockchain for this transaction. If you wish to change the default transaction speed, you may do so in the settings." />
          </RowFixed>
          <div className="text-right flex-1">
            <TYPE.black fontSize={14}>
              {networkFee?.networkFee
                ? formatStringToNumber(networkFee?.networkFee, 8) + ' ' + getNativeSymbol(chainId)
                : '-'}{' '}
              (${formatStringToNumber(nativePriceUsd * networkFee?.networkFee)})
            </TYPE.black>
          </div>
        </RowBetween>

        {ethFee && (
          <RowBetween>
            <RowFixed flex={1}>
              <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel} style={{ whiteSpace: 'nowrap' }}>
                Swap Fee In {getNativeSymbol(chainId)}
              </TYPE.black>
              <QuestionHelper
                text={`There is a 0.25% fee in ${getNativeSymbol(
                  chainId
                )} for each trade. Some tokens may take an additional portion of their fees in ${getNativeSymbol(
                  chainId
                )}. This is displayed here in the total fee amount.`}
                size={'xs'}
              />
            </RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} style={{ textAlign: 'right' }} flex={1}>
              {ethFee
                ? `${formatStringToNumber(CurrencyAmount.ether(ethFee).toExact(), 8)} ${getNativeSymbol(chainId)}`
                : '-'}{' '}
              (${formatStringToPrice(nativePriceUsd * +CurrencyAmount.ether(ethFee).toExact())})
            </TYPE.black>
          </RowBetween>
        )}

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel}>
              Max {getNativeSymbol(chainId)} Total
            </TYPE.black>
            <QuestionHelper
              size={'xs'}
              text="This is the maximum amount you will spend on this transaction in the chains Native Coin."
            />
          </RowFixed>
          <TYPE.black fontSize={14} color={theme.yellow1} textAlign={'right'}>
            {networkFee?.maxNetworkFee
              ? formatStringToNumber(networkFee?.maxNetworkFee + networkFee?.value, 8) + ' ' + getNativeSymbol(chainId)
              : '-'}{' '}
            (${formatStringToNumber(nativePriceUsd * (networkFee?.maxNetworkFee + networkFee?.value))})
          </TYPE.black>
        </RowBetween>
      </AutoColumn>
    </>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade
  ethFee?: string | undefined
  setShowInverted: any
  showInverted: boolean
  currencies: any
  networkFee?: any
  nativePriceUsd: number
  priceUsd?: any
}

export function AdvancedSwapDetails({
  trade,
  ethFee,
  setShowInverted,
  showInverted,
  currencies,
  networkFee,
  nativePriceUsd,
  priceUsd
}: AdvancedSwapDetailsProps) {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const [allowedSlippage] = useUserSlippageTolerance()

  const showRoute = Boolean(trade && trade.route.path.length > 2)

  return (
    <AutoColumn gap={'0px'} style={{ width: '100%' }}>
      <Divider />
      <AutoColumn gap="md">
        {trade && (
          <>
            <Card borderRadius={'20px'} padding={'0'} marginTop="-12px">
              <AutoColumn gap="4px" justify={'center'}>
                <TradePrice
                  inputCurrency={currencies[Field.INPUT]}
                  outputCurrency={currencies[Field.OUTPUT]}
                  price={trade?.executionPrice}
                  showInverted={showInverted}
                  setShowInverted={setShowInverted}
                />
              </AutoColumn>
            </Card>
            <TradeSummary
              trade={trade}
              allowedSlippage={allowedSlippage}
              ethFee={ethFee}
              networkFee={networkFee}
              nativePriceUsd={nativePriceUsd}
              priceUsd={priceUsd}
            />
            {showRoute && (
              <>
                <SectionBreak />
                <AutoColumn>
                  <RowFixed>
                    <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
                      {t('route')}
                    </TYPE.black>
                    <QuestionHelper text={t('routingThrough')} size={'sm'} />
                  </RowFixed>
                  <SwapRoute trade={trade} />
                </AutoColumn>
              </>
            )}
          </>
        )}
      </AutoColumn>
    </AutoColumn>
  )
}
