import { InjectedConnector } from '@web3-react/injected-connector'
import { BinanceConnector } from '@bscswap/binance-connector'
import { WalletConnectConnector } from './WalletConnector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
import { ChainId } from '@safemoon/sdk'

const BSC_TESTNET_URL = process.env.REACT_APP_BSC_TESTNET_URL
const ETH_TESTNET_URL = process.env.REACT_APP_ETH_TESTNET_URL
const BSC_URL = process.env.REACT_APP_BSC_URL
const ETH_URL = process.env.REACT_APP_ETH_URL
const POLYGON_URL = process.env.REACT_APP_POLYGON_URL
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID
const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

if (typeof BSC_URL === 'undefined' || typeof ETH_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}

export const network = new NetworkConnector({
  urls: {
    [ChainId.BSC_TESTNET]: BSC_TESTNET_URL || '',
    [ChainId.SEPOLIA_TESTNET]: ETH_TESTNET_URL || '',
    [ChainId.MAINNET]: ETH_URL,
    [ChainId.BSC_MAINNET]: BSC_URL,
    [ChainId.POLYGON]: POLYGON_URL || '',
    [ChainId.MUMBAI_TESTNET]: process.env.REACT_APP_MUMBAI_URL || ''
  },
  defaultChainId: parseInt(CHAIN_ID || '') || ChainId.BSC_MAINNET
})

export const networkEth = new NetworkConnector({
  urls: {
    [ChainId.BSC_TESTNET]: BSC_TESTNET_URL || '',
    [ChainId.SEPOLIA_TESTNET]: ETH_TESTNET_URL || '',
    [ChainId.MAINNET]: ETH_URL,
    [ChainId.BSC_MAINNET]: BSC_URL,
    [ChainId.POLYGON]: POLYGON_URL || '',
    [ChainId.MUMBAI_TESTNET]: process.env.REACT_APP_MUMBAI_URL || ''
  },
  defaultChainId: parseInt(ChainId.MAINNET as any) || ChainId.BSC_MAINNET
})

export const networkPolygon = new NetworkConnector({
  urls: {
    [ChainId.BSC_TESTNET]: BSC_TESTNET_URL || '',
    [ChainId.SEPOLIA_TESTNET]: ETH_TESTNET_URL || '',
    [ChainId.MAINNET]: ETH_URL,
    [ChainId.BSC_MAINNET]: BSC_URL,
    [ChainId.POLYGON]: POLYGON_URL || '',
    [ChainId.MUMBAI_TESTNET]: process.env.REACT_APP_MUMBAI_URL || ''
  },
  defaultChainId: parseInt(ChainId.POLYGON as any) || ChainId.BSC_MAINNET
})

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 11155111, 80001, 137]
})

export const binanceinjected = new BinanceConnector({
  supportedChainIds: [56, 97, 1]
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.MAINNET, ChainId.BSC_MAINNET, ChainId.POLYGON]
})


export const walletconnectBSC = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.BSC_MAINNET, ChainId.MAINNET, ChainId.POLYGON]
})

// mainnet only
export const walletconnectPolygon = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.POLYGON, ChainId.BSC_MAINNET, ChainId.MAINNET]
})

// mainnet only
export const walletconnectTestnet = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.SEPOLIA_TESTNET, ChainId.BSC_TESTNET, ChainId.MUMBAI_TESTNET],
})

export const walletconnectBSCTestnet = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.BSC_TESTNET, ChainId.SEPOLIA_TESTNET, ChainId.MUMBAI_TESTNET],
})

// mainnet only
export const walletconnectPolygonTestnet = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.MUMBAI_TESTNET, ChainId.BSC_TESTNET, ChainId.SEPOLIA_TESTNET],
})

// mainnet only
export const trustwallet = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.MAINNET, ChainId.BSC_MAINNET, ChainId.POLYGON]
})

export const trustwalletBSC = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.BSC_MAINNET, ChainId.MAINNET, ChainId.POLYGON]
})

// mainnet only
export const trustwalletPolygon = new WalletConnectConnector({
  projectId: 'b64df0521d25ed5108cd242cfa8412e5',
  chains: [ChainId.POLYGON, ChainId.BSC_MAINNET, ChainId.MAINNET]
})

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1]
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: ETH_URL,
  appName: 'SafemoonSwap',
  appLogoUrl:
    'https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg'
})
