import { CurrencyAmount, ETHER, JSBI } from '@safemoon/sdk'
import { MIN_ETH } from '../constants'

/**
 * Given some token amount, return the max that can be spent of it
 * @param currencyAmount to return max of
 */
export function maxAmountSpend(currencyAmount?: CurrencyAmount, ethFee?: string): CurrencyAmount | undefined {
  if (!currencyAmount) return
  if (currencyAmount.currency === ETHER) {
    const ethFeeWithBuffer = ethFee
      ? JSBI.divide(JSBI.multiply(JSBI.BigInt(ethFee), JSBI.BigInt(110)), JSBI.BigInt(100))
      : MIN_ETH
    // if (JSBI.greaterThan(currencyAmount.raw, MIN_ETH)) {
    if (JSBI.greaterThan(currencyAmount.raw, ethFeeWithBuffer)) {
      return CurrencyAmount.ether(JSBI.subtract(currencyAmount.raw, ethFeeWithBuffer))
    } else {
      return CurrencyAmount.ether(JSBI.BigInt(0))
    }
  }
  return currencyAmount
}
