import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import { NavLink, Link } from 'react-router-dom'
import SVG from 'react-inlinesvg'

// import ArrowLeft from '../../assets/icons/arrow-left-3.svg'

import Row, { RowBetween } from '../Row'
import QuestionHelper from '../QuestionHelper'
import { ButtonGray } from '../Button'
import { NETWORK_TYPE } from '../WalletModal'
import { useActiveWeb3React } from '../../hooks'
import { SFM_V2 } from '../../constants'

const Tabs = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 30px;
  justify-content: space-evenly;
  margin-bottom: 2rem;
`

const LimitedTabs = styled(Tabs)`
  background-color: ${({ theme }) => theme.bgDark};
  height: 40px;
  padding: 5px;
  border-radius: 20px;
`

const WalletTabs = styled(Tabs)`
  background-color: ${({ theme }) => theme.bgDark};
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 15px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  font-size: 16px;
  font-weight: bold;
  transition: all ease 0.3s;
  flex: 1;

  &.${activeClassName} {
    width: 78px;
    font-weight: bold;
    color: black;
    background-color: ${({ theme }) => theme.primaryMain};
  }

  :hover,
  :focus {
  }
`

const WalletButton = styled.button<{ active?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme, active }) => (active ? `${theme.black} !important` : theme.text3)};
  background-color: ${({ theme, active }) => (active ? theme.primaryMain : 'transparent')};
  font-weight: ${({ active }) => (active ? 'bold' : '600')};
  border: none;
  font-size: 0.875rem;
  width: 50%;
  transition: all ease 0.3s;

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
    outline: none;
  }
`

const ActiveText = styled.div`
  font-weight: bold;
  font-size: 22px;
  margin-left: 10px;
  color: #edf7f6;
`

const StyledArrowLeft = styled(SVG).attrs(props => ({
  ...props,
  src: '/images/right-arrow.svg',
  width: 24,
  height: 24
}))`
  color: ${({ theme }) => theme.text1};
  width: 24px;
  height: 24px;
`

const HistoryLink = styled(Link)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const HistoryLinkButton = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const StyledGrayButton = styled(ButtonGray)`
  height: 40px;
  border-radius: 12px;
  color: ${({ theme }) => theme.text1};
  max-width: 103px;
`

export function SwapPoolTabs({
  active,
  viewMode,
  showBalance
}: {
  active: 'swap' | 'pool' | 'bridge'
  viewMode?: any
  showBalance?: any
}) {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  return (
    <LimitedTabs style={{ marginBottom: '20px' }}>
      <StyledNavLink
        id={`swap-nav-link`}
        to={
          viewMode
            ? `/swap?viewMode=${viewMode}&showBalance=${showBalance}&inputCurrency=ETH&outputCurrency=${SFM_V2[chainId]}`
            : '/swap'
        }
        isActive={() => active === 'swap'}
      >
        {t('swap')}
      </StyledNavLink>
      <StyledNavLink
        id={`swap-nav-link`}
        to={viewMode ? `/bridge?viewMode=${viewMode}&showBalance=${showBalance}` : '/bridge'}
        isActive={() => active === 'bridge'}
      >
        {t('Bridge')}
      </StyledNavLink>
    </LimitedTabs>
  )
}

export function ConnectWalletTabs({
  active,
  onChangeProviders
}: {
  active: string
  onChangeProviders: (newNetwork: string) => void
}) {
  return (
    <WalletTabs style={{ marginBottom: '20px' }}>
      <WalletButton
        id={`bsc-network`}
        active={active === NETWORK_TYPE.BSC}
        onClick={onChangeProviders.bind(this, NETWORK_TYPE.BSC)}
      >
        BSC
      </WalletButton>
      <WalletButton
        id={`ethereum-network`}
        active={active === NETWORK_TYPE.ETH}
        onClick={onChangeProviders.bind(this, NETWORK_TYPE.ETH)}
      >
        Ethereum
      </WalletButton>
      <WalletButton
        id={`polygon-network`}
        active={active === NETWORK_TYPE.POLYGON}
        onClick={onChangeProviders.bind(this, NETWORK_TYPE.POLYGON)}
      >
        Polygon
      </WalletButton>
    </WalletTabs>
  )
}

export function FindPoolTabs() {
  const { t } = useTranslation()
  return (
    <Tabs>
      <RowBetween>
        <Row align={'center'}>
          <HistoryLink to="/pool">
            <StyledArrowLeft />
          </HistoryLink>
          <ActiveText>{t('importpool')}</ActiveText>
        </Row>
        <QuestionHelper text={t('usethistool')} />
      </RowBetween>
    </Tabs>
  )
}

export function AddTabs() {
  const { t } = useTranslation()
  return (
    <Tabs>
      <RowBetween>
        <Row align={'center'}>
          <HistoryLink to="/pool">
            <StyledArrowLeft />
          </HistoryLink>
          <ActiveText>{t('addLiquidity')}</ActiveText>
        </Row>
        <QuestionHelper text={t('whenyouaddliquidity')} />
      </RowBetween>
    </Tabs>
  )
}

export function AddSTPTabs() {
  const { t } = useTranslation()
  return (
    <Tabs>
      <RowBetween>
        <Row align={'center'}>
          <HistoryLink to="/manage-stp">
            <StyledArrowLeft />
          </HistoryLink>
          <ActiveText>{t('addEditSTP')}</ActiveText>
        </Row>
      </RowBetween>
    </Tabs>
  )
}

export function RemoveTabs({ onChangeDetails, detailed }: any) {
  const { t } = useTranslation()
  return (
    <Tabs>
      <RowBetween>
        <Row align={'center'}>
          <HistoryLink to="/pool">
            <StyledArrowLeft />
          </HistoryLink>
          <ActiveText>{t('removeLiquidity')}</ActiveText>
        </Row>
        <StyledGrayButton onClick={onChangeDetails}>{detailed ? 'Simple' : 'Detailed'}</StyledGrayButton>
      </RowBetween>
    </Tabs>
  )
}

export function SelectToken({
  onDismiss,
  tooltipOpen,
  hideQuestion,
  title
}: {
  tooltipOpen?: boolean
  onDismiss: () => void
  hideQuestion?: boolean
  title?: string
}) {
  const { t } = useTranslation()
  return (
    <Tabs style={{ marginBottom: '0.625rem' }}>
      <RowBetween>
        <Row align={'center'}>
          <HistoryLinkButton onClick={onDismiss}>
            <StyledArrowLeft />
          </HistoryLinkButton>
          <ActiveText>{title || t('selectToken')}</ActiveText>
        </Row>
        {!hideQuestion && <QuestionHelper disabled={tooltipOpen} text={t('findAToken')} />}
      </RowBetween>
    </Tabs>
  )
}

export function SettingsTab({ onDismiss }: { onDismiss: any }) {
  return (
    <Tabs style={{ marginBottom: '0.625rem' }}>
      <RowBetween>
        <Row align={'center'}>
          <HistoryLinkButton onClick={onDismiss}>
            <StyledArrowLeft />
          </HistoryLinkButton>
          <ActiveText>Settings</ActiveText>
        </Row>
      </RowBetween>
    </Tabs>
  )
}
