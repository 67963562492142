import React from 'react'
import styled from 'styled-components'
import { escapeRegExp } from '../../utils'

const fontSizeByLength = (value: any) => {
  if (!value || `${value}`?.length <= 12) {
    return '20px'
  }
  if (value?.length > 12 && value?.length < 16) {
    return '16px'
  }
  return '14px'
}

const StyledInput = styled.input<{ error?: boolean; fontSize?: string; align?: string; value: any }>`
  color: ${({ error, theme }) => (error ? theme.red1 : theme.inputColor)};
  max-width: 100%;
  min-width: 24px;
  width: 24px;
  height: 44px;
  position: relative;
  font-weight: 700;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: transparent;
  font-size: ${({ fontSize, value }) => fontSize ?? fontSizeByLength(value)};
  text-align: ${({ align }) => align && align};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  -webkit-appearance: textfield;
  margin-left: 0.5rem;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-left: 0.25rem;
    font-size: 
  `}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 1rem;
  `}
`

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`)

export const Input = React.memo(function InnerInput({
  value,
  onUserInput,
  placeholder,
  ...rest
}: {
  value: string | number
  onUserInput: (string) => void
  error?: boolean
  fontSize?: string
  align?: 'right' | 'left'
} & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>) {
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput(nextUserInput)
    }
  }

  return (
    <StyledInput
      {...rest}
      value={value}
      onChange={event => {
        // replace commas with periods, because uniswap exclusively uses period as the decimal separator
        enforcer(event.target.value.replace(/,/g, '.'))
      }}
      // universal input options
      inputMode="decimal"
      title="Token Amount"
      autoComplete="off"
      autoCorrect="off"
      // text-specific options
      type="text"
      pattern="^[0-9]*[.,]?[0-9]*$"
      placeholder={placeholder || '0.0'}
      minLength={1}
      maxLength={79}
      spellCheck="false"
    />
  )
})

export default Input

// const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group
