import { Trade, TradeType, CurrencyAmount, Token, WETH, ChainId } from '@safemoon/sdk'
import React, { useContext, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { Field } from '../../state/swap/actions'
import { TYPE } from '../../theme'
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  formatExecutionPrice,
  warningSeverity
} from '../../utils/prices'
import { ButtonError } from '../Button'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { AutoRow, RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { StyledBalanceMaxMiniSmall, SwapCallbackError } from './styleds'
import { useActiveWeb3React } from '../../hooks'
import getTokenSymbol, { getNativeSymbol } from '../../utils/getTokenSymbol'
import { formatStringToNumber, formatStringToPrice, isStableCoin } from '../../utils'
import ExchangePrice from '../../assets/icons/exchange-price.svg'
import SVG from 'react-inlinesvg'
import { useGasPrice } from '../../state/user/hooks'

export default function SwapModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  ethFee,
  swapErrorMessage,
  disabledConfirm,
  networkFee,
  nativePriceUsd,
  priceUsd
}: {
  trade: Trade
  allowedSlippage: number
  ethFee?: string
  onConfirm: () => void
  swapErrorMessage: string | undefined
  disabledConfirm: boolean
  networkFee?: any
  nativePriceUsd: number
  priceUsd?: any
}) {
  const { chainId } = useActiveWeb3React()
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const theme = useContext(ThemeContext)
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    allowedSlippage,
    trade
  ])

  const gasPrice = +useGasPrice() / 10 ** 9

  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const severity = warningSeverity(priceImpactWithoutFee)

  const isTradeRouter = true

  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT

  const minimumReceivedUsd = useMemo(() => {
    const symbol = isExactIn ? trade.outputAmount.currency?.symbol : trade.inputAmount.currency?.symbol
    let address = isExactIn
      ? (trade.outputAmount.currency as Token)?.address
      : (trade.inputAmount.currency as Token)?.address
    if (symbol === 'ETH' && !address) {
      address = WETH[chainId as ChainId]?.address
    }  else if (symbol === 'ETH' && address && chainId === 56) {
      address = WETH[1]?.address
    }

    return slippageAdjustedAmounts && slippageAdjustedAmounts[isExactIn ? Field.OUTPUT : Field.INPUT]
      ? +(slippageAdjustedAmounts[isExactIn ? Field.OUTPUT : Field.INPUT]?.toFixed() || 0) *
          priceUsd[address?.toLowerCase()]
      : 0
  }, [isExactIn, slippageAdjustedAmounts, priceUsd, chainId])

  return (
    <>
      <AutoColumn gap="0px">
        <div className="flex items-center justify-center mb-[12px]">
          <Text fontWeight={500} fontSize={14} color={theme.text2}>
            {formatExecutionPrice(trade, showInverted, chainId)}
          </Text>
          <StyledBalanceMaxMiniSmall onClick={() => setShowInverted(!showInverted)}>
            <SVG color={theme.text1} src={ExchangePrice} width={18} height={18} />
          </StyledBalanceMaxMiniSmall>
        </div>

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel} style={{ whiteSpace: 'nowrap' }}>
              {trade.tradeType === TradeType.EXACT_INPUT ? 'Minimum received' : 'Maximum sold'}
            </TYPE.black>
            <QuestionHelper
              size={'xs'}
              text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
            />
          </RowFixed>
          <TYPE.black fontSize={14} color={theme.secondary2} flex={1} textAlign="right">
            {trade.tradeType === TradeType.EXACT_INPUT
              ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
              : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}{' '}
            {trade.tradeType === TradeType.EXACT_INPUT
              ? getTokenSymbol(trade.outputAmount.currency, chainId)
              : getTokenSymbol(trade.inputAmount.currency, chainId)}{' '}
            {!isStableCoin(isExactIn ? trade.outputAmount.currency?.symbol : trade.inputAmount.currency?.symbol) &&
              `($${formatStringToPrice(minimumReceivedUsd)})`}
          </TYPE.black>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TYPE.black color={theme.textLabel} fontSize={14} fontWeight={400}>
              Price Impact
            </TYPE.black>
            <QuestionHelper
              size={'xs'}
              text="The difference between the market price and your price due to trade size."
            />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel}>
              Network Fee ({gasPrice} GWEI)
            </TYPE.black>
            <QuestionHelper
              size={'xs'}
              text="This is the gas fee charged by the blockchain for this transaction. If you wish to change the default transaction speed, you may do so in the settings."
            />
          </RowFixed>
          <div className="text-right">
            <TYPE.black fontSize={14}>
              {networkFee?.networkFee
                ? formatStringToNumber(networkFee?.networkFee, 8) + ' ' + getNativeSymbol(chainId)
                : '-'}{' '}
              (${formatStringToNumber(nativePriceUsd * networkFee?.networkFee)})
            </TYPE.black>
          </div>
        </RowBetween>
        {!isTradeRouter && (
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel}>
                SafeMoon Swap Fee
              </TYPE.black>
              <QuestionHelper
                size={'xs'}
                text="A portion of each trade (0.25%) goes towards supporting the ecosystem."
              />
            </RowFixed>
            <TYPE.black fontSize={14}>
              {realizedLPFee
                ? realizedLPFee?.toSignificant(6) + ' ' + getTokenSymbol(trade.inputAmount.currency, chainId)
                : '-'}
            </TYPE.black>
          </RowBetween>
        )}

        {ethFee && (
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel} style={{ whiteSpace: 'nowrap' }}>
                Swap Fee In {getNativeSymbol(chainId)}
              </TYPE.black>
              <QuestionHelper
                size={'xs'}
                text={`There is a 0.25% fee in ${getNativeSymbol(
                  chainId
                )} for each trade. Some tokens may take an additional portion of their fees in ${getNativeSymbol(
                  chainId
                )}. This is displayed here in the total fee amount.`}
              />
            </RowFixed>
            <TYPE.black fontSize={14} style={{ textAlign: 'right' }} flex={1}>
              {formatStringToNumber(CurrencyAmount.ether(ethFee).toExact(), 8)} {getNativeSymbol(chainId)} ($
              {formatStringToPrice(nativePriceUsd * +CurrencyAmount.ether(ethFee).toExact())})
            </TYPE.black>
          </RowBetween>
        )}

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel}>
              Max {getNativeSymbol(chainId)} Total
            </TYPE.black>
            <QuestionHelper
              size={'xs'}
              text="This is the maximum amount you will spend on this transaction in the chains Native Coin."
            />
          </RowFixed>
          <TYPE.black fontSize={14} color={theme.yellow1} textAlign={'right'}>
            {networkFee
              ? formatStringToNumber(networkFee?.maxNetworkFee + networkFee?.value, 8) + ' ' + getNativeSymbol(chainId)
              : '-'}{' '}
            (${formatStringToNumber(nativePriceUsd * (networkFee?.maxNetworkFee + networkFee?.value))})
          </TYPE.black>
        </RowBetween>
      </AutoColumn>

      <AutoRow>
        <ButtonError
          onClick={onConfirm}
          disabled={disabledConfirm}
          error={severity > 2}
          style={{ margin: '10px 0 0 0' }}
          id="confirm-swap-or-send"
        >
          <Text fontSize={'14px'} fontWeight={'bold'}>
            {severity > 2 ? 'Swap Anyway' : 'Confirm Swap'}
          </Text>
        </ButtonError>

        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
