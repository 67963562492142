import classNames from 'classnames'
import React from 'react'
import ReactPlayer from 'react-player'
import useParsedQueryString from '../../../hooks/useParsedQueryString'
import './Banner.css'

const Banner = ({ zIndex = 1 }: { zIndex?: number }) => {
  const query = useParsedQueryString()
  return (
    <div className={'videoWrapper'} style={{ zIndex }} id="bg-video">
      <div className={classNames('bg-video', query.viewMode === 'mobile' && 'bg-video-mobile')} />
      {query.viewMode !== 'mobile' && (
        <ReactPlayer
          url={'https://safemoon-dashboard.s3.ap-southeast-1.amazonaws.com/SAFEMOON+GREEN+SWIRL+20100-0800.mp4'}
          className={'reactPlayer'}
          playing={true}
          width="100%"
          height="auto"
          controls={false}
          loop={true}
          muted={true}
          autoPlay={true}
          playsInline={true}
          playsinline={true}
        />
      )}

      <div className="overlay-banner" />
    </div>
  )
}

export default Banner
