import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { abi as ISafeswapRouterABI } from '../constants/abis/ISafeswapRouter02.json'
import { abi as ISafeswapRouterETHABI } from '../constants/abis/ISafeswapRouterETH.json'
import ISafeSwapTradeABI from '../constants/abis/ISafeSwapTradeABI.json'
import { ROUTER_ADDRESS } from '../constants'
import { TRADE_SWAP_ROUTER_ADDRESS } from '../constants'
import { ChainId, Currency, CurrencyAmount, ETHER, JSBI, Percent, Token } from '@safemoon/sdk'
import { TokenAddressMap } from '../state/lists/hooks'
import Web3 from 'web3'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  1: 'etherscan.io',
  3: 'ropsten.etherscan.io',
  4: 'rinkeby.etherscan.io',
  5: 'goerli.etherscan.io',
  42: 'kovan.etherscan.io',
  56: 'bscscan.com',
  97: 'testnet.bscscan.com',
  11155111: 'sepolia.etherscan.io',
  80001: 'mumbai.polygonscan.com',
  137: 'polygonscan.com'
}

const ANALYSIS_PREFIXES: { [chainId in ChainId]: string } = {
  1: '',
  3: '',
  4: '',
  5: '',
  42: '',
  56: '',
  97: 'testnet.',
  11155111: '',
  80001: '',
  137: ''
}

export function getEtherscanLink(chainId: ChainId, data: string, type: 'transaction' | 'token' | 'address'): string {
  const prefix = `https://${ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[1]}`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

export function getAnalysisLink(chainId: ChainId): string {
  return `https://${ANALYSIS_PREFIXES[chainId] || ANALYSIS_PREFIXES[1]}bscswap.info`
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)), // Amount IN
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)) // Amount Min Out
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(_: ChainId, library: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS[_ || ChainId.BSC_TESTNET], ISafeswapRouterABI, library, account)
}

export function getRouterETHContract(_: ChainId, library: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS[_ || ChainId.BSC_TESTNET], ISafeswapRouterETHABI, library, account)
}

export function getTradeSwapContract(_: ChainId, library: Web3Provider, account?: string): Contract {
  return getContract(TRADE_SWAP_ROUTER_ADDRESS[_ || ChainId.BSC_TESTNET], ISafeSwapTradeABI, library, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isDefaultToken(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}

export function getExplore(chainId: number) {
  return chainId === 97 || chainId === 56
    ? 'BscScan'
    : chainId === ChainId.MUMBAI_TESTNET || chainId === ChainId.POLYGON
    ? 'Polygonscan'
    : 'Etherscan'
}

export function routingNumber(value: any, maximumFractionDigits: number) {
  if (!value && value !== 0) {
    return '-'
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits || 2
  })

  return formatter.format(value).replace(/,/g, ',')
}

export function formatStringToNumber(value: any, maximumFractionDigits = 2) {
  if (!value && value !== 0) {
    return '-'
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits
  })

  return formatter.format(value).replace(/,/g, ',')
}

export function formatStringToPrice(value: any) {
  if (!value && value !== 0) {
    return '-'
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: +value > 0.01 ? 2 : 8
  })

  return formatter.format(value).replace(/,/g, ',')
}

export function isStableCoin(symbol: string | undefined) {
  return symbol === 'BUSD' || symbol === 'USDC' || symbol === 'USDT'
}

export const DECIMALS_TO_UNIT: any = {
  9: 'nano',
  12: 'szabo',
  18: 'ether',
  6: 'picoether'
}

const convertNumber = (val: any, maximumFractionDigits = 0) =>
  val ? formatStringToNumber(val, maximumFractionDigits).replace(/,/g, '') : 0

export const toWei = ({ val, decimals }: { val: number | string; decimals: number }) => {
  return convertNumber(
    DECIMALS_TO_UNIT[decimals]
      ? Web3.utils.toWei(`${convertNumber(val, decimals)}`, DECIMALS_TO_UNIT[decimals])
      : +val * 10 ** decimals,
    0
  )
}

export const fromWei = ({ val, decimals }: any) => {
  return convertNumber(
    DECIMALS_TO_UNIT[decimals] ? Web3.utils.fromWei(val || '0', DECIMALS_TO_UNIT[decimals]) : `${val / 10 ** decimals}`,
    20
  )
}

export const convertObjectToSearchParams = values => {
  if (values) {
    // console.log(values)
    const search = Object.entries(values)
      .filter(entry => entry[1])
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    return `?${search}`
  }
  return ''
}
