/* eslint-disable */
import React from 'react'
import closeIcon from '../../../assets/images/close.svg'
import Modal from '../../../components/Modal'
import './SlippageWarningPopup.css'

interface Props {
  show: boolean
  handleClose: any,
  handleHideSlippageWarning: any,
  slippage: number
}

const SlippageWarningPopup = ({ show, handleClose, handleHideSlippageWarning, slippage }: Props) => {
  return (
    <Modal
      isOpen={show}
      onDismiss={handleClose}
      // alignSelf="flex-end"
      forceMaxHeight={'70vh'}
    >
      
      <div className="slippage-warning">
        <h2 className="slippage-warning-title">Auto Slippage Applied</h2>
        <p className="slippage-warning-text">
          We have automatically set the recommended slippage settings for this trade. This allows for contract tokenomics and price fluctuations.
        </p>
        
        <div className={'center'}>
          <div className={'slippage-value'}>
            {slippage / 100}%
          </div>
        </div>
        <p className="slippage-warning-note">
          <b>Note:</b> You may edit this setting at your own discretion in the SWaP settings.
        </p>
        
        <div className='action-center'>
          <a
            className="btn btnMain"
            onClick={() => {
              handleClose()
            }}
          >
            Confirm
          </a>
        </div>
       
        <a className={'btn-link'}
          onClick={() => {
            handleHideSlippageWarning()
            handleClose()
          }}
        >
          Don't show me again
        </a>
      </div>
    </Modal>
  )
}

export default SlippageWarningPopup
