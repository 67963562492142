import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42,
  BSC_MAINNET = 56,
  BSC_TESTNET = 97,
  SEPOLIA_TESTNET = 11155111,
  MUMBAI_TESTNET=80001,
  POLYGON=137
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

type ChainAddress = {
  [chainId in ChainId]: string
}

export const FACTORY_ADDRESS: ChainAddress = {
  [ChainId.MAINNET]: '0x7F09d4bE6bbF4b0fF0C97ca5c486a166198aEAeE',
  [ChainId.ROPSTEN]: '0xDfD8bbA37423950bD8050C65E698610C57E55cea',
  [ChainId.RINKEBY]: '',
  [ChainId.GÖRLI]: '',
  [ChainId.KOVAN]: '',
  [ChainId.BSC_MAINNET]: '0x505751023083BfC7D2AFB0588717dCC6182e54B2',
  [ChainId.BSC_TESTNET]: '0x738673CD45AE0d18815f65551D935DbcB12c9E46',
  [ChainId.SEPOLIA_TESTNET]: '0xb33Db820e58E38b3f8E0C812e957AAE6Df268942',
  [ChainId.MUMBAI_TESTNET]: '0x5A4cfB39cA25F33B459eC29Cb3f30aA80b59be6E',
  [ChainId.POLYGON]: '0x8a00a21f2f654c4545a0272888377929dc3df4df'
}

export const INIT_CODE_HASH: ChainAddress = {
  [ChainId.MAINNET]: '0xaf22d00bd30ebd0976c262e993c9d8df7423973a794d1d429c2322f0dc80e3ed',
  [ChainId.ROPSTEN]: '0x8bc8f8336dcfcba44096a139671d89637695b1be1cf88aad5d7de56ae35b8bfd',
  [ChainId.RINKEBY]: '',
  [ChainId.GÖRLI]: '',
  [ChainId.KOVAN]: '',
  [ChainId.BSC_MAINNET]: '0xc5c0f41407c4baa59af66c427d16b88ec7c38d2a749bd6347c223ef8b0abb7cf',
  [ChainId.BSC_TESTNET]: '0x0fc91b77b75a47fecaa0cf17936d873d34644cc50bd4adf2d450b8c0e78b1489',
  [ChainId.SEPOLIA_TESTNET]: '0xb21c16207ec15577d37acb3faa375985c08cc4006a7c3b9de5ec6bd868730576',
  [ChainId.MUMBAI_TESTNET]: '0xb21c16207ec15577d37acb3faa375985c08cc4006a7c3b9de5ec6bd868730576',
  [ChainId.POLYGON]: '0xaf22d00bd30ebd0976c262e993c9d8df7423973a794d1d429c2322f0dc80e3ed'
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _9975 = JSBI.BigInt(9975)
export const _10000 = JSBI.BigInt(10000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
