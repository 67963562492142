import React, { useCallback, useMemo, useState } from 'react'
import { SelectToken } from '../../../components/NavigationTabs'
import { BirdgeChain, BRIDGES, getChainType } from '../../../constants'
import { PaddedColumn } from '../../../components/SearchModal/styleds'
import Modal from '../../../components/Modal'
import Column from '../../../components/Column'
import { routingNumber } from '../../../utils'
import classNames from 'classnames'
import { useActiveWeb3React } from '../../../hooks'
import Tooltip from '../../../components/Tooltip'
import { isMobile } from 'react-device-detect'
import useParsedQueryString from '../../../hooks/useParsedQueryString'
// import { BLACKLIST_TOKENS_SAFEMOON_V1 } from '../../constants'

const ChainIntro = ({ item }: { item: BirdgeChain }) => (
  <div className="tooltip-wrapper tooltip-wrapper-large">
    <p className="text-[16px] font-bold leading-[16px] main-text mb-[6px]">{item.chainName}</p>
    <p className="text-[13px] leading-[16px] label-text2 mb-0">
      This is the SFM Token on the {item.chainName} blockchain. When you bridge your SFM to another chain, you will
      receive the corresponding amount of tokens 1:1 on the receiving chain.
    </p>
  </div>
)

interface CurrencySearchModalProps {
  isOpen?: boolean
  onDismiss?: () => void
  handleSelectChain?: (chain: BirdgeChain) => void
  otherChain?: any
  chain: any
  balances: any
  id: string
  priceUsd?: any
}

const ChainItem = ({ item, balances, onSelectChain, chain, otherChain, id }: any) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const open = useCallback(() => setShowTooltip(true), [setShowTooltip])
  const close = useCallback(() => setShowTooltip(false), [setShowTooltip])

  return (
    <div
      className={classNames(
        'flex items-center cursor-pointer px-[25px] py-[15px] chain-item',
        chain?.chain?.chainId === item.chainId && 'opacity-[0.5]',
        otherChain?.chain?.chainId === item.chainId && 'opacity-[0.5]'
      )}
      onClick={() => {
        if (id !== 'bridge-output' || item.chainId !== otherChain?.chain?.chainId) {
          onSelectChain(item)
        }
      }}
      onMouseEnter={!isMobile && open}
      onMouseLeave={!isMobile && close}
    >
      <div className="flex flex-1 items-center">
        {item.images.map((image, i) => (
          <img src={image} className="w-[20px] lg:w-[24px] mr-[2px] lg:mr-[5px]" alt="icon" key={i} />
        ))}
        <p className="ml-[5px] lg:ml-[10px] text-[15px] lg:text-[16px] font-bold main-text mb-0">{item.shortChain}</p>
      </div>
      <p className="text-[15px] lg:text-[20px] font-bold secondary-text mb-[0] ml-[8px] flex-1">
        {routingNumber(balances[item.chainId], 2)}
      </p>

      <Tooltip component={<ChainIntro item={item} />} show={showTooltip}>
        <div
          className={classNames('ml-[20px] lg:ml-[40px] opacity-[0.5] hover:opacity-[1]')}
          onClick={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onMouseEnter={open}
          onMouseLeave={close}
        >
          <img src="/images/info.svg" className="w-[20px] lg:w-[24px]" alt="icon" />
        </div>
      </Tooltip>
    </div>
  )
}

export default function CurrencySearchModal({
  isOpen,
  onDismiss,
  handleSelectChain,
  otherChain,
  chain,
  balances,
  id
}: CurrencySearchModalProps) {
  const { chainId } = useActiveWeb3React()

  const query = useParsedQueryString()

  const onSelectChain = useCallback(
    (chain: BirdgeChain) => {
      handleSelectChain(chain)
      onDismiss()
    },
    [onDismiss, handleSelectChain]
  )

  const listChains = useMemo(() => {
    return BRIDGES(query?.viewMode === 'mobile' ? 'mainnet' : getChainType(chainId))
  }, [chainId, query])

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      maxHeight={70}
      minHeight={0}
      forceMaxHeight={'70vh'}
      // alignSelf="flex-end"
    >
      <Column style={{ width: '100%' }}>
        <PaddedColumn gap="0">
          <SelectToken onDismiss={onDismiss} hideQuestion={true} title="Select a Chain" />
        </PaddedColumn>
        <div className="bg-black">
          {listChains?.map((item: BirdgeChain) => (
            <ChainItem
              item={item}
              key={item.chainId}
              onSelectChain={onSelectChain}
              balances={balances}
              chain={chain}
              otherChain={otherChain}
              id={id}
            />
          ))}
        </div>
      </Column>
    </Modal>
  )
}
