import Modal from '../Modal'
import React, { useState } from 'react'
import SelectMoonpayToken from './SelectToken'

export default function BuySellModal({ open, onDismiss }: { open: boolean; onDismiss: any }) {
  const [type, setType] = useState<string>('')
  return (
    <>
      <Modal isOpen={open} onDismiss={onDismiss} maxHeight={451}>
        <div className="p-[25px]">
          <div className="relative text-center mb-[45px]">
            <img src="/images/moonpaylogo.svg" className="w-[150px] mx-[auto]" alt="moonpay-logo" />
            <button onClick={onDismiss} className="absolute right-[0] top-[0]">
              <img src="/images/close.svg" className="w-[24px]" alt="close" />
            </button>
          </div>
          <p className="text-[18px] leading-[26px] mb-[20px] text-center">
            Purchase with your debit and credit card, local bank transfer, Apple Pay, and Google Pay.
          </p>
          <button
            className="btn btnPurple mb-[35px] w-full"
            onClick={() => {
              setType('BUY')
            }}
          >
            Buy
          </button>
          <p className="text-[18px] leading-[26px] mb-[20px] text-center">
            Sell and receive funds in your bank account.
          </p>
          <button
            className="btn btnPurple mb-[20px] w-full"
            onClick={() => {
              setType('SELL')
            }}
          >
            Sell
          </button>
        </div>
      </Modal>
      <SelectMoonpayToken
        open={!!type}
        onDismiss={() => {
          setType('')
        }}
        type={type}
      />
    </>
  )
}
