import { AbstractConnector } from '@web3-react/abstract-connector'
import { ChainId, JSBI, Percent, Token, WETH } from '@safemoon/sdk'

import { binanceinjected, injected, trustwallet, trustwalletBSC, trustwalletPolygon, walletconnect, walletconnectBSC, walletconnectPolygon } from '../connectors'
import { BigNumber } from '@ethersproject/bignumber'
import { BigNumberish } from 'ethers'
import mitt from 'mitt'

import BNBLogo from '../assets/images/bnb.svg'
import EthereumLogo from '../assets/images/eth_icon.svg'
import MATICLogo from '../assets/images/matic.png'
import safemoonLogo from '../assets/images/SafeMoonWallet.svg'

export interface BirdgeChain {
  images: string[]
  chain: string
  chainId: number
  sfmAddress: string
  rpcUrl: string
  chainName: string
  color: string
  decimals: number
  shortChain: string
  currency: string
  hexChain: string
  network: number
}


export enum PopupTypes {
  BLACKLIST_WALLET = 'BLACKLIST_WALLET',
  BLACKLIST_TOKEN = 'BLACKLIST_TOKEN'
}

export const popupEmitter = mitt()

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

type ChainAddress = {
  [chainId in ChainId]: string
}

type Address = {
  [chainId in ChainId]: string
}

type Tokens = {
  [chainId in ChainId]: Token
}

const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const FACTORY_ADDRESS: ChainAddress = {
  [ChainId.MAINNET]: '0x7F09d4bE6bbF4b0fF0C97ca5c486a166198aEAeE',
  [ChainId.ROPSTEN]: '0xDfD8bbA37423950bD8050C65E698610C57E55cea',
  [ChainId.RINKEBY]: '',
  [ChainId.GÖRLI]: '',
  [ChainId.KOVAN]: '',
  [ChainId.BSC_MAINNET]: '0x505751023083BfC7D2AFB0588717dCC6182e54B2',
  [ChainId.BSC_TESTNET]: '0x4f5c641eE8F06a64D5BA792AEDaB502a73878c22',
  [ChainId.SEPOLIA_TESTNET]: '0xb33Db820e58E38b3f8E0C812e957AAE6Df268942',
  [ChainId.MUMBAI_TESTNET]: '0x5A4cfB39cA25F33B459eC29Cb3f30aA80b59be6E',
  [ChainId.POLYGON]: '0x8a00a21f2f654c4545a0272888377929dc3df4df'
}

export const ROUTER_ADDRESS: ChainAddress = {
  [ChainId.MAINNET]: '0x0a654cb371fbc3c49ccb176984d64e86fc931eea',
  [ChainId.RINKEBY]: '',
  [ChainId.GÖRLI]: '',
  [ChainId.KOVAN]: '',
  [ChainId.BSC_MAINNET]: '0x6AC68913d8FcCD52d196B09e6bC0205735A4be5f',
  [ChainId.ROPSTEN]: '0x713702D3fb45BC9765d3A00e0B37c33f9CE9Ec91',
  [ChainId.BSC_TESTNET]: '0x922a689ac9787F7e8EDEe6e819d123cad4F29FAA',
  [ChainId.SEPOLIA_TESTNET]: '0x7F2E935C802d25354C637Cda804AbE01115bB718',
  [ChainId.MUMBAI_TESTNET]: '0x6b22BE9CA44eF99076Cbe0C0A92E4caB786bF65A',
  [ChainId.POLYGON]: '0x9a7ba8eba135b789efddb15e0ce42572efa3b792'
}

export const TRADE_SWAP_ROUTER_ADDRESS: ChainAddress = {
  [ChainId.MAINNET]: '0x3816016D0855A2b35b90DddcAe4A2e9Ad690947d',
  [ChainId.RINKEBY]: '',
  [ChainId.GÖRLI]: '',
  [ChainId.KOVAN]: '',
  [ChainId.BSC_MAINNET]: '0x524BC73fCb4fB70E2E84dC08EFE255252A3b026E',
  [ChainId.ROPSTEN]: '',
  [ChainId.BSC_TESTNET]: '0xd4434ffdB434999ebbBEF0D98A2364FADb75241C',
  [ChainId.SEPOLIA_TESTNET]: '0x15a8381D8A6E209Ba0CA3F27FE848119B3A6feD0',
  [ChainId.MUMBAI_TESTNET]: '0x522058567D3B315B258990c5175e8F6159752cc4',
  [ChainId.POLYGON]: '0x4911f3ec32d4e72bbab1f387944f4e350adcee96'
}

export const DAI = new Token(ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18, 'DAI', 'Dai Stablecoin')
export const USDC = new Token(ChainId.MAINNET, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD//C')
export const USDT = new Token(ChainId.MAINNET, '0xdAC17F958D2ee523a2206206994597C13D831ec7', 6, 'USDT', 'Tether USD')
export const COMP = new Token(ChainId.MAINNET, '0xc00e94Cb662C3520282E6f5717214004A7f26888', 18, 'COMP', 'Compound')
export const MKR = new Token(ChainId.MAINNET, '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2', 18, 'MKR', 'Maker')
export const AMPL = new Token(ChainId.MAINNET, '0xD46bA6D942050d489DBd938a2C909A5d5039A161', 9, 'AMPL', 'Ampleforth')

export const BAI = new Token(
  ChainId.BSC_MAINNET,
  '0xaA8012a0Ea627767545a8E435C2A2BD51f60173D',
  18,
  'BAI',
  'BAI Stablecoin'
)
export const B_DAI = new Token(
  ChainId.BSC_MAINNET,
  '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
  18,
  'DAI',
  'Dai Token'
)
export const BUSD = new Token(
  ChainId.BSC_MAINNET,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'BUSD Token'
)
export const B_USDT = new Token(
  ChainId.BSC_MAINNET,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether USD'
)

export const T_DAI = new Token(
  ChainId.BSC_TESTNET,
  '0xEC5dCb5Dbf4B114C9d0F65BcCAb49EC54F6A0867',
  18,
  'DAI',
  'Dai Token'
)
export const T_BUSD = new Token(
  ChainId.BSC_TESTNET,
  '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
  6,
  'BUSD',
  'BUSD Token'
)
export const T_USDT = new Token(
  ChainId.BSC_TESTNET,
  '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
  6,
  'USDT',
  'Tether USD'
)

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WETH[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WETH[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH[ChainId.KOVAN]],
  [ChainId.BSC_MAINNET]: [WETH[ChainId.BSC_MAINNET]],
  [ChainId.BSC_TESTNET]: [WETH[ChainId.BSC_TESTNET]],
  [ChainId.SEPOLIA_TESTNET]: [WETH[ChainId.SEPOLIA_TESTNET]],
  [ChainId.MUMBAI_TESTNET]: [WETH[ChainId.MUMBAI_TESTNET]],
  [ChainId.POLYGON]: [WETH[ChainId.POLYGON]]
}

export const SFM_V1: Address = {
  [ChainId.MAINNET]: ZERO_ADDRESS,
  [ChainId.ROPSTEN]: ZERO_ADDRESS,
  [ChainId.RINKEBY]: ZERO_ADDRESS,
  [ChainId.GÖRLI]: ZERO_ADDRESS,
  [ChainId.KOVAN]: ZERO_ADDRESS,
  [ChainId.BSC_MAINNET]: process.env.REACT_APP_SAFEMOON_TOKEN || ZERO_ADDRESS,
  [ChainId.BSC_TESTNET]: process.env.REACT_APP_SAFEMOON_TESTNET_TOKEN || ZERO_ADDRESS,
  [ChainId.SEPOLIA_TESTNET]: ZERO_ADDRESS,
  [ChainId.MUMBAI_TESTNET]: ZERO_ADDRESS,
  [ChainId.POLYGON]: ZERO_ADDRESS
}

export const SFM_V2: Address = {
  [ChainId.MAINNET]: process.env.REACT_APP_SAFEMOONV2_ETHERIUM_TOKEN || ZERO_ADDRESS,
  [ChainId.ROPSTEN]: ZERO_ADDRESS,
  [ChainId.RINKEBY]: ZERO_ADDRESS,
  [ChainId.GÖRLI]: ZERO_ADDRESS,
  [ChainId.KOVAN]: ZERO_ADDRESS,
  [ChainId.BSC_MAINNET]: process.env.REACT_APP_SAFEMOONV2_TOKEN || ZERO_ADDRESS,
  [ChainId.BSC_TESTNET]: process.env.REACT_APP_SAFEMOONV2_BSC_TESTNET_TOKEN || ZERO_ADDRESS,
  [ChainId.SEPOLIA_TESTNET]: process.env.REACT_APP_SAFEMOONV2_SEPOLIA_TESTNET_TOKEN || ZERO_ADDRESS,
  [ChainId.MUMBAI_TESTNET]: process.env.REACT_APP_SAFEMOONV2_MUMBAI_TESTNET_TOKEN || ZERO_ADDRESS,
  [ChainId.POLYGON]: process.env.REACT_APP_SAFEMOONV2_POLYGON_TOKEN || ZERO_ADDRESS
}

export const MIGRATION: Address = {
  [ChainId.MAINNET]: ZERO_ADDRESS,
  [ChainId.ROPSTEN]: ZERO_ADDRESS,
  [ChainId.RINKEBY]: ZERO_ADDRESS,
  [ChainId.GÖRLI]: ZERO_ADDRESS,
  [ChainId.KOVAN]: ZERO_ADDRESS,
  [ChainId.BSC_MAINNET]: process.env.REACT_APP_MIGRATION || ZERO_ADDRESS,
  [ChainId.BSC_TESTNET]: process.env.REACT_APP_MIGRATION_TESTNET || ZERO_ADDRESS,
  [ChainId.SEPOLIA_TESTNET]: ZERO_ADDRESS,
  [ChainId.MUMBAI_TESTNET]: ZERO_ADDRESS,
  [ChainId.POLYGON]: ZERO_ADDRESS
}

export const SfmV1: Tokens = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, SFM_V1[ChainId.MAINNET], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.ROPSTEN]: new Token(ChainId.ROPSTEN, SFM_V1[ChainId.ROPSTEN], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, SFM_V1[ChainId.RINKEBY], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, SFM_V1[ChainId.GÖRLI], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, SFM_V1[ChainId.KOVAN], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.SEPOLIA_TESTNET]: new Token(
    ChainId.SEPOLIA_TESTNET,
    SFM_V1[ChainId.SEPOLIA_TESTNET],
    9,
    'SAFEMOON',
    'SafeMoon'
  ),
  [ChainId.MUMBAI_TESTNET]: new Token(
    ChainId.MUMBAI_TESTNET,
    SFM_V1[ChainId.MUMBAI_TESTNET],
    9,
    'SAFEMOON',
    'SafeMoon'
  ),
  [ChainId.POLYGON]: new Token(ChainId.POLYGON, SFM_V1[ChainId.POLYGON], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.BSC_MAINNET]: new Token(ChainId.BSC_MAINNET, SFM_V1[ChainId.BSC_MAINNET], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.BSC_TESTNET]: new Token(ChainId.BSC_TESTNET, SFM_V1[ChainId.BSC_TESTNET], 9, 'SAFEMOON', 'SafeMoon')
}

export const SfmV2: Tokens = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, SFM_V1[ChainId.MAINNET], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.ROPSTEN]: new Token(ChainId.ROPSTEN, SFM_V1[ChainId.ROPSTEN], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, SFM_V1[ChainId.RINKEBY], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, SFM_V1[ChainId.GÖRLI], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, SFM_V1[ChainId.KOVAN], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.SEPOLIA_TESTNET]: new Token(
    ChainId.SEPOLIA_TESTNET,
    SFM_V2[ChainId.SEPOLIA_TESTNET],
    9,
    'SAFEMOON',
    'SafeMoon'
  ),
  [ChainId.MUMBAI_TESTNET]: new Token(
    ChainId.MUMBAI_TESTNET,
    SFM_V2[ChainId.MUMBAI_TESTNET],
    9,
    'SAFEMOON',
    'SafeMoon'
  ),
  [ChainId.POLYGON]: new Token(ChainId.POLYGON, SFM_V2[ChainId.POLYGON], 9, 'SAFEMOON', 'SafeMoon'),
  [ChainId.BSC_MAINNET]: new Token(ChainId.BSC_MAINNET, SFM_V2[ChainId.BSC_MAINNET], 9, 'SFM', 'SafeMoon V2'),
  [ChainId.BSC_TESTNET]: new Token(ChainId.BSC_TESTNET, SFM_V2[ChainId.BSC_TESTNET], 9, 'SFM', 'SafeMoon V2')
}

export const consolidation = {
  addresses: {
    v1: SFM_V1,
    v2: SFM_V2,
    migration: MIGRATION
  },
  tokens: {
    v1: SfmV1,
    v2: SfmV2
  }
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, COMP, MKR],
  [ChainId.BSC_MAINNET]: [...WETH_ONLY[ChainId.BSC_MAINNET], BAI, B_USDT, BUSD, B_DAI]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT],
  [ChainId.BSC_MAINNET]: [...WETH_ONLY[ChainId.BSC_MAINNET], BAI, B_USDT, BUSD, B_DAI],
  [ChainId.BSC_TESTNET]: [...WETH_ONLY[ChainId.BSC_TESTNET], T_USDT, T_BUSD, T_DAI]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT],
  [ChainId.BSC_MAINNET]: [...WETH_ONLY[ChainId.BSC_MAINNET], BAI, B_USDT, BUSD, B_DAI],
  [ChainId.BSC_TESTNET]: [...WETH_ONLY[ChainId.BSC_TESTNET], T_USDT, T_BUSD, T_DAI]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
      new Token(ChainId.MAINNET, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin')
    ],
    [USDC, USDT],
    [DAI, USDT]
  ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: boolean
  mobileOnly?: true
  chainIds: number[]
  hrefIos?: string
}


export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  SAFEMOON_WALLET_BSC: {
    connector: walletconnectBSC,
    name: 'SafeMoon Wallet',
    iconName: 'SafeMoonWallet.svg',
    description: 'A secure place to store and trade your SafeMoon.',
    href: null,
    color: '#008a81',
    chainIds: [56],
  },
  SAFEMOON_WALLET_BSC_MOBILE: {
    connector: injected,
    name: 'SafeMoon Wallet',
    iconName: 'SafeMoonWallet.svg',
    description: 'A secure place to store and trade your SafeMoon.',
    href: `https://safemoon.com/open-dapp-browser?url=${location.href}`,
    hrefIos: `https://safemoon.com/open-dapp-browser?url=${location.href}`,
    color: '#008a81',
    chainIds: [56],
    mobile: true,
    mobileOnly: true
  },
  SAFEMOON_WALLET: {
    connector: walletconnect,
    name: 'SafeMoon Wallet',
    iconName: 'SafeMoonWallet.svg',
    description: 'A secure place to store and trade your SafeMoon.',
    href: null,
    color: '#008a81',
    chainIds: [1],
  },
  SAFEMOON_WALLET_MOBILE: {
    connector: injected,
    name: 'SafeMoon Wallet',
    iconName: 'SafeMoonWallet.svg',
    description: 'A secure place to store and trade your SafeMoon.',
    href: `https://safemoon.com/open-dapp-browser?url=${location.href}`,
    hrefIos: `https://safemoon.com/open-dapp-browser?url=${location.href}`,
    color: '#008a81',
    chainIds: [1],
    mobile: true,
    mobileOnly: true
  },
  SAFEMOON_WALLET_POLYGON: {
    connector: walletconnectPolygon,
    name: 'SafeMoon Wallet',
    iconName: 'SafeMoonWallet.svg',
    description: 'A secure place to store and trade your SafeMoon.',
    href: null,
    color: '#008a81',
    chainIds: [137],
  },
  SAFEMOON_WALLET_POLYGON_MOBILE: {
    connector: injected,
    name: 'SafeMoon Wallet',
    iconName: 'SafeMoonWallet.svg',
    description: 'A secure place to store and trade your SafeMoon.',
    href: `https://safemoon.com/open-dapp-browser?url=${location.href}`,
    hrefIos: `https://safemoon.com/open-dapp-browser?url=${location.href}`,
    color: '#008a81',
    chainIds: [137],
    mobile: true,
    mobileOnly: true
  },
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
    chainIds: [1, 3, 56, 97, 11155111, 80001, 137]
  },
  WALLETCONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#3375BB',
    chainIds: [1],
    mobile: true
  },
  WALLETCONNECT_BSC: {
    connector: walletconnectBSC,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#3375BB',
    chainIds: [56],
    mobile: true
  },
  WALLETCONNECT_POLYGON: {
    connector: walletconnectPolygon,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#3375BB',
    chainIds: [137],
    mobile: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
    chainIds: [1, 3, 56, 97, 11155111, 80001, 137],
    mobile: false
  },
  METAMASK_MOBILE: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: `https://metamask.app.link/dapp/${location.href}`,
    hrefIos: `https://metamask.app.link/dapp/${location.href}`,
    color: '#E8831D',
    chainIds: [1, 3, 56, 97, 11155111, 80001, 137],
    mobile: true,
    mobileOnly: true
  },
  TRUSTWALLET_MOBILE: {
    connector: injected,
    name: 'TrustWallet',
    iconName: 'trustwallet.svg',
    description: 'The most trusted & secure crypto wallet',
    href: `https://link.trustwallet.com/open_url?coin_id=60&url=${location.href}`,
    hrefIos: `https://link.trustwallet.com/open_url?coin_id=60&url=${location.href}`,
    color: '#3375BB',
    chainIds: [1],
    mobile: true,
    mobileOnly: true
  },
  TRUSTWALLET_DESKTOP: {
    connector: trustwallet,
    name: 'TrustWallet',
    iconName: 'trustwallet.svg',
    description: 'The most trusted & secure crypto wallet',
    href: null,
    color: '#3375BB',
    chainIds: [1]
  },
  TRUSTWALLET_TESTNET: {
    connector: injected,
    name: 'TrustWallet',
    iconName: 'trustwallet.svg',
    description: 'The most trusted & secure crypto wallet',
    href: null,
    color: '#3375BB',
    chainIds: [3, 97]
  },
  TRUSTWALLET_BSC_MOBILE: {
    connector: injected,
    name: 'TrustWallet',
    iconName: 'trustwallet.svg',
    description: 'The most trusted & secure crypto wallet',
    href: `https://link.trustwallet.com/open_url?coin_id=60&url=${location.href}`,
    hrefIos: `https://link.trustwallet.com/open_url?coin_id=60&url=${location.href}`,
    color: '#3375BB',
    chainIds: [56],
    mobile: true,
    mobileOnly: true
  },
  TRUSTWALLET_BSC_DESKTOP: {
    connector: trustwalletBSC,
    name: 'TrustWallet',
    iconName: 'trustwallet.svg',
    description: 'The most trusted & secure crypto wallet',
    href: null,
    color: '#3375BB',
    chainIds: [56]
  },
  TRUSTWALLET_POLYGON_MOBILE: {
    connector: injected,
    name: 'TrustWallet',
    iconName: 'trustwallet.svg',
    description: 'The most trusted & secure crypto wallet',
    href: `https://link.trustwallet.com/open_url?coin_id=60&url=${location.href}`,
    hrefIos: `https://link.trustwallet.com/open_url?coin_id=60&url=${location.href}`,
    color: '#3375BB',
    chainIds: [137],
    mobile: true,
    mobileOnly: true
  },
  TRUSTWALLET_POLYGON_DESKTOP: {
    connector: trustwalletPolygon,
    name: 'TrustWallet',
    iconName: 'trustwallet.svg',
    description: 'The most trusted & secure crypto wallet',
    href: null,
    color: '#3375BB',
    chainIds: [137]
  },
  BINANCE: {
    connector: binanceinjected,
    name: 'Binance Chain Wallet',
    iconName: 'bnb.svg',
    description: 'A Crypto Wallet for Binance Smart Chain',
    href: null,
    color: '#F9A825',
    chainIds: [56, 97, 1]
  }
  // MATHWALLET: {
  //   connector: injected,
  //   name: 'MathWallet',
  //   iconName: 'mathwallet.svg',
  //   description: 'Your Gateway to the World of Blockchain',
  //   href: null,
  //   color: '#000000',
  //   chainIds: [1, 3, 56, 97, 11155111, 80001, 137]
  // }
}

export const NetworkContextName = 'NETWORK'

export const appEnv: string = process.env.REACT_APP_ENV || 'development'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.multiply(JSBI.BigInt(2), JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16))) // .02 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))

export const MAX_PRIORITY_FEE: BigNumberish = BigNumber.from(2).mul(10 ** 9)

export const ETHERSCAN_API_KEY: string = process.env.REACT_APP_ETHERSCAN_API_KEY || ''
export const POLYGON_API_KEY: string = process.env.REACT_APP_POLYGON_API_KEY || ''

// the Safemoon Default token list lives here
export const DEFAULT_TOKEN_LIST_URL: string =
  process.env.REACT_APP_DEFAULT_TOKEN_LIST_URL || 'https://marketdata.safemoon.com/api/swap/v8/tokens'

export const MOONPAY_TOKEN_LIST_URL: string =
  process.env.REACT_APP_MOONPAY_TOKEN_LIST_URL || 'https://marketdata.safemoon.com/api/moonpay/public/token/list'

export const SIGN_MOONPAY_URL: string =
  process.env.REACT_APP_SIGN_MOONPAY_URL || 'https://marketdata.safemoon.com/api/moonpay/sign-moonpay'

export const DEFAULT_TOKEN_BLACK_LIST: string =
  process.env.REACT_APP_DEFAULT_TOKEN_BLACK_LIST_URL || 'https://marketdata.safemoon.com/api/swap/token-blacklist'

export const DEFAULT_WALLET_BLACK_LIST_URL: string =
  process.env.REACT_APP_DEFAULT_WALLET_BLACK_LIST_URL ||
  'https://marketdata.safemoon.com/api/swap/v2/walletaddress-blacklist'

export const CHECK_WALLETADDRESS_BLACKLIST_URL: string =
  process.env.REACT_APP_CHECK_WALLETADDRESS_BLACKLIST_URL ||
  'https://marketdata.safemoon.com/api/swap/check-walletaddress-blacklist'


export const MESSAGE_ALERTS_URL: string =
  process.env.REACT_APP_MESSAGE_ALERTS_URL ||
  'https://marketdata.safemoon.com/api/dapp-browser/message-alert/public/list'

export const SAVE_LOG_URL: string = process.env.REACT_APP_SAVE_LOG_URL || 'https://marketdata.safemoon.com/api/swap/save-log'


export const BLACKLIST_TOKENS_SAFEMOON_V1 = [
  '0X8076C74C5E3F5852037F31FF0093EEB8C8ADD8D3',
  '0XDD6999EC25948811D7C671051F5B4E44B175239E'
]

export const TOKENS_SAFEMOON_V2 = [
  '0X42981D0BFBAF196529376EE702F2A9EB9092FCB5',
  '0X8DF4AAE3A61BC5092EC332F40378A67A70187D46'
]

export const MOONPAY_KEY = process.env.REACT_APP_MOONPAY_KEY

export const BRIDGES = (type: 'testnet' | 'mainnet') => [
  {
    images: [EthereumLogo, safemoonLogo],
    chain: 'ethereum',
    chainId: type === 'testnet' ? ChainId.SEPOLIA_TESTNET : ChainId.MAINNET,
    sfmAddress: SFM_V2[type === 'testnet' ? ChainId.SEPOLIA_TESTNET : ChainId.MAINNET],
    rpcUrl: type === 'testnet' ? process.env.REACT_APP_ETH_TESTNET_URL : process.env.REACT_APP_ETH_URL,
    chainName: 'Ethereum',
    color: '#DEDEDE',
    decimals: 9,
    currency: 'ETH',
    shortChain: 'ETH',
    hexChain: type === 'testnet' ? '0xaa36a7' : '0x1',
    network: 1
  },
  {
    images: [BNBLogo, safemoonLogo],
    chain: 'bsc',
    chainId: type === 'testnet' ? ChainId.BSC_TESTNET : ChainId.BSC_MAINNET,
    sfmAddress: SFM_V2[type === 'testnet' ? ChainId.BSC_TESTNET : ChainId.BSC_MAINNET],
    rpcUrl: type === 'testnet' ? process.env.REACT_APP_BSC_TESTNET_URL : process.env.REACT_APP_BSC_URL,
    chainName: 'Binance Smart Chain',
    color: '#F3BA2F',
    decimals: 9,
    currency: 'BNB',
    shortChain: 'BSC',
    hexChain: type === 'testnet' ? '0x61' : '0x38',
    network: 2
  },
  {
    images: [MATICLogo, safemoonLogo],
    chain: 'polygon',
    chainId: type === 'testnet' ? ChainId.MUMBAI_TESTNET : ChainId.POLYGON,
    sfmAddress: SFM_V2[type === 'testnet' ? ChainId.MUMBAI_TESTNET : ChainId.POLYGON],
    rpcUrl: type === 'testnet' ? process.env.REACT_APP_MUMBAI_URL : process.env.REACT_APP_POLYGON_URL,
    chainName: 'Polygon',
    color: '#8247E5',
    decimals: 9,
    currency: 'MATIC',
    shortChain: 'Polygon',
    hexChain: type === 'testnet' ? '0x13881' : '0x89',
    network: 3
  }
]

export const getChainType = (chainId: number) => {
  return chainId === ChainId.BSC_MAINNET || chainId === ChainId.MAINNET || chainId === ChainId.POLYGON
    ? 'mainnet'
    : 'testnet'
}

export const BRIDGE_CONTRACT: any = {
  [ChainId.BSC_TESTNET]: process.env.REACT_APP_BSC_TESTNET_BRIDGE_CONTRACT,
  [ChainId.SEPOLIA_TESTNET]: process.env.REACT_APP_SEPOLIA_BRIDGE_CONTRACT,
  [ChainId.MUMBAI_TESTNET]: process.env.REACT_APP_MUMBAI_BRIDGE_CONTRACT,
  [ChainId.BSC_MAINNET]: process.env.REACT_APP_BSC_MAINNET_BRIDGE_CONTRACT,
  [ChainId.MAINNET]: process.env.REACT_APP_MAINNET_BRIDGE_CONTRACT,
  [ChainId.POLYGON]: process.env.REACT_APP_POLYGON_BRIDGE_CONTRACT
}

export const BRIDGE_API_URL = process.env.REACT_APP_BRIDGE_API_URL

export const HEX_CHAINS: any = {
  1: '0x1',
  56: '0x38',
  137: '0x89'
}

export const BINANCE_NETWORK: any = {
  1: 'eth-mainnet',
  56: 'bsc-mainnet',
  137: ''
}
