import { ChainId } from '@safemoon/sdk'
import React, { useEffect, useRef, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { Text } from 'rebass'
// import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import BuySellModal from '../Moonpay/BuySellModal'

import styled from 'styled-components'

// import Logo from '../../assets/images/SafeMoon-Logo.png'
// import LogoType from '../../assets/images/SafeMoonSwap-LogoType.svg'
import { useActiveWeb3React } from '../../hooks'

// import Settings from '../Settings'

import { RowBetween } from '../Row'
import Web3Status from '../Web3Status'
import {
  useGetModals,
  useWalletModalChainId,
  useWalletModalOpen,
  useWalletModalToggle,
  useWrongNetwork
} from '../../state/application/hooks'
import classNames from 'classnames'
import { StyledDropDown } from '../CurrencyInputPanel'
import Web3 from 'web3'
import { binanceinjected, injected, network, networkEth, networkPolygon } from '../../connectors'
import { WalletConnectConnector } from '../../connectors/WalletConnector'
import { MouseoverTooltip } from '../Tooltip'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { useHistory, useLocation } from 'react-router-dom'
import { BINANCE_NETWORK, SFM_V2 } from '../../constants'
import { WrongNetwork } from '../WalletModal'
import { convertObjectToSearchParams } from '../../utils'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 3;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    // padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

const AccountElement = styled.div<{ active: boolean; viewMode?: any }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, viewMode }) => (viewMode === 'mobile' ? theme.bgDark : theme.bgMain)};
  border-radius: 24px;
  white-space: nowrap;
  width: 100%;
  height: 48px;

  :focus {
    border: 1px solid blue;
  }
`

const NETWORK_COLORS: any = {
  [ChainId.MAINNET]: '#DEDEDE',
  [ChainId.BSC_MAINNET]: '#F3BA2F',
  [ChainId.BSC_TESTNET]: '#F3BA2F',
  [ChainId.SEPOLIA_TESTNET]: '#DEDEDE',
  [ChainId.MUMBAI_TESTNET]: '#8247E5',
  [ChainId.POLYGON]: '#8247E5'
}

const NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: 'Ethereum',
  [ChainId.RINKEBY]: 'Wrong Network',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Wrong Network',
  [ChainId.KOVAN]: 'Wrong Network',
  [ChainId.BSC_MAINNET]: 'BSC Mainnet',
  [ChainId.BSC_TESTNET]: 'BSC Testnet',
  [ChainId.SEPOLIA_TESTNET]: 'Sepolia',
  [ChainId.MUMBAI_TESTNET]: 'Mumbai',
  [ChainId.POLYGON]: 'Polygon'
}

const SHORT_NETWORK_LABELS: { [chainId in ChainId]: string | null } = {
  [ChainId.MAINNET]: 'ETH',
  [ChainId.RINKEBY]: 'Wrong Network',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Wrong Network',
  [ChainId.KOVAN]: 'Wrong Network',
  [ChainId.BSC_MAINNET]: 'BSC',
  [ChainId.BSC_TESTNET]: 'BSC',
  [ChainId.SEPOLIA_TESTNET]: 'Sepolia',
  [ChainId.MUMBAI_TESTNET]: 'Mumbai',
  [ChainId.POLYGON]: 'Polygon'
}

const HEX_CHAINS = {
  1: '0x1',
  56: '0x38',
  137: '0x89'
}

const NETWORKS = [
  {
    name: 'BSC Mainnet',
    shortName: 'BSC',
    chainId: ChainId.BSC_MAINNET
  },
  {
    name: 'Ethereum',
    shortName: 'ETH',
    chainId: ChainId.MAINNET
  },
  {
    name: 'Polygon',
    shortName: 'Polygon',
    chainId: ChainId.POLYGON
  }
]

const SelectNetwork = ({ viewMode, showBalance }: { viewMode?: any, showBalance?: any }) => {
  const [showMenus, setShowMenus] = useState(false)
  const ref = useRef(null)
  const { chainId: currentChainId, activate, connector } = useActiveWeb3React()

  const toggleWalletModal = useWalletModalToggle()
  const walletModalOpen = useWalletModalOpen()
  const [chainId, setChainId] = useWalletModalChainId(currentChainId)
  const { toggleWrongNetwork } = useWrongNetwork()

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !(ref.current as any)?.contains(event.target)) {
        setShowMenus(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  const history = useHistory()
  const location = useLocation()

  const handleChangeNetwork = async selectedChainId => {
    if (connector instanceof WalletConnectConnector) {
      connector.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: HEX_CHAINS[selectedChainId] }]
      })
      if (location.pathname?.indexOf('/swap') !== -1 && viewMode !== 'mobile') {
        history.push('/swap')
      }
      setShowMenus(false)
      return
    }
    if (window.ethereum && connector === injected) {
      if (chainId !== selectedChainId) {
        if (viewMode === 'mobile' && location.pathname?.indexOf('/swap') !== -1) {
          history.push(`/swap?inputCurrency=ETH&outputCurrency=${SFM_V2[selectedChainId]}&viewMode=mobile&showBalance=${showBalance}`)
        }

        const web3 = new Web3(window.ethereum as any)
        await (window.ethereum as any)?.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: HEX_CHAINS[selectedChainId] }] // chainId must be in hexadecimal numbers
        })
        const accounts = await web3.eth.getAccounts()
        if (accounts[0]) {
          activate(injected, undefined, true).catch(error => {
            console.error('Failed to activate after accounts changed', error)
          })
        }
      }
    } else if (connector === binanceinjected && window.BinanceChain) {
      if (+selectedChainId === ChainId.POLYGON) {
        toggleWrongNetwork()
      } else {
        await window.BinanceChain?.switchNetwork(BINANCE_NETWORK[selectedChainId])
        activate(binanceinjected, undefined, true).catch(error => {
          console.error('Failed to activate after accounts changed', error)
        })
      }
    } else {
      if (walletModalOpen) {
        setChainId(selectedChainId)
      } else {
        if (selectedChainId === 1) {
          activate(networkEth)
        } else if (selectedChainId === 56) {
          activate(network)
        } else if (selectedChainId === 137) {
          activate(networkPolygon)
        }
        
        toggleWalletModal(selectedChainId)
      }
    }

    if (location.pathname?.indexOf('/swap') !== -1 && viewMode !== 'mobile') {
      history.push('/swap')
    }

    setShowMenus(false)
  }

  return (
    <div className="relative" ref={ref}>
      <div
        className="pl-[0] flex items-center cursor-pointer w-[auto] md:w-[140px]"
        onClick={() => {
          setShowMenus(prev => !prev)
        }}
      >
        <div className="w-[10px] h-[10px] rounded-full" style={{ backgroundColor: NETWORK_COLORS[chainId] }} />
        <span className="font-bold text-[16px] secondary-text px-[8px] flex-1">
          {isMobile ? SHORT_NETWORK_LABELS[chainId] : NETWORK_LABELS[chainId]}
        </span>
        <StyledDropDown selected={false} />
      </div>

      {showMenus && (
        <div className="network-select">
          {NETWORKS?.map(item => (
            <div
              className={classNames(
                'cursor-pointer py-[8px] md:py-[12px] text-[16px] font-bold label-text2 consolidate',
                chainId === item.chainId && 'text-white'
              )}
              onClick={() => {
                handleChangeNetwork(item.chainId)
              }}
              key={item.chainId}
            >
              {isMobile ? item.shortName : item.name}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

// const SafeMoonIcon = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const NetworkStatus = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  `};
`

// const SafeMoonLogo = styled.img`
//   width: 40px;
//   height: 40px;
//   margin-right: 12px;

//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     margin-right: 0;
//   `}
// `

// const SafeMoonType = styled.img`
//   width: 116px;
//   height: 34px;

//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     display: none;
//   `}
// `

const StyledRowBetween = styled(RowBetween)`
  padding: 1.5rem 5rem 0 5rem;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem 1rem 0 1rem;
  `}
`

export default function Header({numberMessages, setQuery}: {numberMessages?: number; setQuery?: any}) {
  const { account, chainId } = useActiveWeb3React()
  const [openBuySellMoonpay, setOpenBuySellMoonpay] = useState(false)
  const [requestConnectFromMoonpay, setRequestConnectFromMoonpay] = useState(false)
  

  const toggleWalletModal = useWalletModalToggle()

  const query = useParsedQueryString()
  const modals = useGetModals()
  const history = useHistory()
  const location = useLocation()

  const [oldQuery, setOldQuery] = useState(query)

  useEffect(() => {
    setQuery(query)
    if (query.viewMode !== oldQuery.viewMode) {
      history.replace(`${location.pathname}${convertObjectToSearchParams({
        viewMode: oldQuery.viewMode, showBalance: oldQuery.showBalance, ...query})}`)
    } else {
      setOldQuery(query)
    }
  }, [query])

  useEffect(() => {
    const element = document.getElementById('bg-video')
    if (element && modals?.length === 0) {
      element.style.zIndex = '1'
    }
  }, [modals])

  const handleShowBuySellMoonpay = () => {
    if (account) {
      setOpenBuySellMoonpay(prev => !prev)
    } else {
      setRequestConnectFromMoonpay(true)
      toggleWalletModal()
    }
  }

  useEffect(() => {
    if (requestConnectFromMoonpay && account) {
      setOpenBuySellMoonpay(true)
      setRequestConnectFromMoonpay(false)
    }
  }, [account, requestConnectFromMoonpay])

  return (
    <HeaderFrame style={(numberMessages > 0 && (!isMobile || isTablet)) ? {top: `${75*numberMessages}px`} : {}}>
      <StyledRowBetween style={{ alignItems: 'flex-start' }}>
        <HeaderElement>
          <Title href={query.viewMode !== 'mobile' ? 'https://safemoon.com/' : undefined} target="_blank">
            <img
              src={isMobile ? '/logo.png' : '/images/swap-logo.svg'}
              className={classNames(isMobile ? 'w-[48px]' : 'w-[240px]')}
              alt="logo"
              style={{
                width: isMobile ? '48px' : '240px'
              }}
            />
          </Title>
          
        </HeaderElement>
        <HeaderControls>
          <HeaderElement>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }} viewMode={query?.viewMode}>
              {!isMobile && query.viewMode !== 'mobile' && (
                <div className="hidden md:block">
                  <Web3Status />
                </div>
              )}

              {(account || (!isMobile && query.viewMode !== 'mobile')) && NETWORK_LABELS[chainId] && (
                <NetworkStatus style={{ flexShrink: 0 }} pl="20px" pr="20px" fontWeight={500}>
                  <SelectNetwork viewMode={query?.viewMode} showBalance={query?.showBalance} />
                </NetworkStatus>
              )}
            </AccountElement>
          </HeaderElement>
          {(isMobile || query?.viewMode === 'mobile') && (
            <div className="block">
              <Web3Status />
            </div>
          )}

          {/* <HeaderElementWrap>
            <Settings />
          </HeaderElementWrap> */}
        </HeaderControls>
      </StyledRowBetween>
      

      <BuySellModal
        open={openBuySellMoonpay}
        onDismiss={() => {
          setOpenBuySellMoonpay(false)
        }}
      />

      <WrongNetwork />
    </HeaderFrame>
  )
}
