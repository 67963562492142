import { createReducer, nanoid } from '@reduxjs/toolkit'
import {
  addPopup,
  PopupContent,
  removePopup,
  toggleWalletModal,
  toggleSettingsMenu,
  updateBlockNumber,
  setWalletModalChainId,
  toggleWrongNetwork,
  addModal,
  removeModal
} from './actions'

type PopupList = Array<{ key: string; show: boolean; content: PopupContent }>

export interface ModalI {
  id: number
}

export interface ApplicationState {
  blockNumber: { [chainId: number]: number }
  popupList: PopupList
  walletModalOpen: boolean
  settingsMenuOpen: boolean
  openModalChainId?: number
  showWrongNetwork: boolean
  modals: ModalI[]
}

const initialState: ApplicationState = {
  blockNumber: {},
  popupList: [],
  walletModalOpen: false,
  settingsMenuOpen: false,
  openModalChainId: undefined,
  showWrongNetwork: false,
  modals: []
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateBlockNumber, (state, action) => {
      const { chainId, blockNumber } = action.payload
      if (typeof state.blockNumber[chainId] !== 'number') {
        state.blockNumber[chainId] = blockNumber
      } else {
        state.blockNumber[chainId] = blockNumber //Math.max(blockNumber, state.blockNumber[chainId])
      }
    })
    .addCase(toggleWalletModal, (state, { payload: { chainId } }) => {
      if (chainId) {
        state.openModalChainId = chainId
      }

      state.walletModalOpen = !state.walletModalOpen
    })
    .addCase(setWalletModalChainId, (state, { payload: { chainId } }) => {
      state.openModalChainId = chainId
    })
    .addCase(toggleSettingsMenu, state => {
      state.settingsMenuOpen = !state.settingsMenuOpen
    })
    .addCase(toggleWrongNetwork, state => {
      state.showWrongNetwork = !state.showWrongNetwork
    })
    .addCase(addPopup, (state, { payload: { content, key } }) => {
      state.popupList = (key ? state.popupList.filter(popup => popup.key !== key) : state.popupList).concat([
        {
          key: key || nanoid(),
          show: true,
          content
        }
      ])
    })
    .addCase(removePopup, (state, { payload: { key } }) => {
      state.popupList.forEach(p => {
        if (p.key === key) {
          p.show = false
        }
      })
    })
    .addCase(addModal, (state, { payload: { id } }) => {
      state.modals = [{ id }, ...state.modals]
    })
    .addCase(removeModal, (state, { payload: { id } }) => {
      state.modals = state.modals.filter(modal => modal.id !== id)
    })
)
