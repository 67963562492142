import { useMemo } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { Token } from '@safemoon/sdk'

import { useFFSContract } from '../hooks/useContract'
// import { tryParseAmount } from '../state/swap/hooks'
import { useEthFeeManager } from '../state/user/hooks'
import { useSingleCallResult } from '../state/multicall/hooks'
import { useActiveWeb3React } from '../hooks'

type MethodArg = string | number | BigNumber
// type OptionalMethodInputs = Array<MethodArg | MethodArg[] | undefined> | undefined

export function useSwapFee(amount: MethodArg, _path: Token[] | undefined): string | undefined {
  const contract = useFFSContract()
  const {account} = useActiveWeb3React()

  // console.log('useContract ===>', contract)

  const [userETHFee, setETHFee] = useEthFeeManager()

  const path = _path?.map((token: Token) => token.address)
  const inputs: any = useMemo(() => [path, amount, account], [amount, path, account])

  // console.log('[path, amount, account]', [path, amount, account])

  const { result }: any = useSingleCallResult(contract, 'getFees', inputs)

  // console.log('result swap fee', result?.totalBNBFee)

  const data = result?.totalBNBFee
  // const amountInEth = _path && data && tryParseAmount(data?.toString(), _path[0])

  // amountInEth && setETHFee(amountInEth.raw.toString())
  setETHFee(data?.toString(), result?.dexFee?.toString(), result?.tokenAFee?.toString(), result?.tokenBFee?.toString())

  return useMemo(() => (amount && path?.length && data ? userETHFee : undefined), [amount, userETHFee, path, data])
}
