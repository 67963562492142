import { INITIAL_ALLOWED_SLIPPAGE, DEFAULT_DEADLINE_FROM_NOW } from '../../constants'
import { createReducer } from '@reduxjs/toolkit'
import {
  addSerializedPair,
  addSerializedToken,
  dismissTokenWarning,
  removeSerializedPair,
  removeSerializedToken,
  SerializedPair,
  SerializedToken,
  updateMatchesDarkMode,
  updateUserDarkMode,
  updateVersion,
  updateUserExpertMode,
  updateUserSlippageTolerance,
  updateUserDeadline,
  updateGasPrice,
  updateETHFee,
  updateGasPricesList,
  hideShowSlippageWarning,
  handleShowSlippageWarning,
  setCurrentAccount,
  setCurrentConnector
} from './actions'
import { GAS_PRICE, GAS_PRICE_GWEI } from './hooks'
import { ChainId } from '@safemoon/sdk'
import { parseUnits } from 'ethers/lib/utils'

const currentTimestamp = () => new Date().getTime()

export interface UserState {
  // the timestamp of the last updateVersion action
  lastUpdateVersionTimestamp?: number

  userDarkMode: boolean | null // the user's choice for dark mode or light mode
  matchesDarkMode: boolean // whether the dark mode media query matches

  userExpertMode: boolean

  // user defined slippage tolerance in bips, used in all txns
  userSlippageTolerance: number

  // deadline set by user in minutes, used in all txns
  userDeadline: number

  hideSlippageWarning: boolean

  tokens: {
    [chainId: number]: {
      [address: string]: SerializedToken
    }
  }

  // the token warnings that the user has dismissed
  dismissedTokenWarnings?: {
    [chainId: number]: {
      [tokenAddress: string]: true
    }
  }

  pairs: {
    [chainId: number]: {
      // keyed by token0Address:token1Address
      [key: string]: SerializedPair
    }
  }

  timestamp: number
  gasPrice: string
  ETHFee: string // BNB Fee for swap, as full BigInt string
  dexFee: string,
  tokenAFee: string,
  tokenBFee: string,
  gasPriceType: string
  gasPrices: any
  currentAccount: string
  currentConnector: string
}

function pairKey(token0Address: string, token1Address: string) {
  return `${token0Address};${token1Address}`
}

export const initialState: UserState = {
  userDarkMode: null,
  matchesDarkMode: false,
  userExpertMode: false,
  userSlippageTolerance: INITIAL_ALLOWED_SLIPPAGE,
  userDeadline: DEFAULT_DEADLINE_FROM_NOW,
  tokens: {},
  pairs: {},
  timestamp: currentTimestamp(),
  gasPrice: GAS_PRICE_GWEI.default,
  ETHFee: '0',
  dexFee: '0',
  tokenAFee: '0',
  tokenBFee: '0',
  gasPriceType: 'default',
  hideSlippageWarning: false,
  currentAccount: '',
  currentConnector: '',
  gasPrices: {
    [ChainId.BSC_MAINNET]: {
      ...GAS_PRICE_GWEI
    },
    [ChainId.BSC_TESTNET]: {
      ...GAS_PRICE_GWEI
    },
    [ChainId.MAINNET]: {
      default: parseUnits('60', 'gwei').toString(),
      fast: parseUnits('70', 'gwei').toString(),
      instant: parseUnits('75', 'gwei').toString(),
      testnet: parseUnits(GAS_PRICE.testnet, 'gwei').toString()
    }
  }
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateVersion, state => {
      // slippage isnt being tracked in local storage, reset to default
      if (typeof state.userSlippageTolerance !== 'number') {
        state.userSlippageTolerance = INITIAL_ALLOWED_SLIPPAGE
      }

      // deadline isnt being tracked in local storage, reset to default
      if (typeof state.userDeadline !== 'number') {
        state.userDeadline = DEFAULT_DEADLINE_FROM_NOW
      }

      state.lastUpdateVersionTimestamp = currentTimestamp()
    })
    .addCase(updateUserDarkMode, (state, action) => {
      state.userDarkMode = action.payload.userDarkMode
      state.timestamp = currentTimestamp()
    })
    .addCase(updateMatchesDarkMode, (state, action) => {
      state.matchesDarkMode = action.payload.matchesDarkMode
      state.timestamp = currentTimestamp()
    })
    .addCase(updateUserExpertMode, (state, action) => {
      state.userExpertMode = action.payload.userExpertMode
      state.timestamp = currentTimestamp()
    })
    .addCase(updateUserSlippageTolerance, (state, action) => {
      state.userSlippageTolerance = action.payload.userSlippageTolerance
      state.timestamp = currentTimestamp()
    })
    .addCase(updateUserDeadline, (state, action) => {
      state.userDeadline = action.payload.userDeadline
      state.timestamp = currentTimestamp()
    })
    .addCase(hideShowSlippageWarning, state => {
      state.hideSlippageWarning = true
      state.timestamp = currentTimestamp()
    })
    .addCase(handleShowSlippageWarning, state => {
      state.hideSlippageWarning = false
      state.timestamp = currentTimestamp()
    })
    .addCase(setCurrentAccount, (state, action) => {
      state.currentAccount = action.payload.currentAccount
    })
    .addCase(setCurrentConnector, (state, action) => {
      state.currentConnector = action.payload.currentConnector
    })
    .addCase(addSerializedToken, (state, { payload: { serializedToken } }) => {
      state.tokens[serializedToken.chainId] = state.tokens[serializedToken.chainId] || {}
      state.tokens[serializedToken.chainId][serializedToken.address] = serializedToken
      state.timestamp = currentTimestamp()
    })
    .addCase(removeSerializedToken, (state, { payload: { address, chainId } }) => {
      state.tokens[chainId] = state.tokens[chainId] || {}
      delete state.tokens[chainId][address]
      state.timestamp = currentTimestamp()
    })
    .addCase(dismissTokenWarning, (state, { payload: { chainId, tokenAddress } }) => {
      state.dismissedTokenWarnings = state.dismissedTokenWarnings ?? {}
      state.dismissedTokenWarnings[chainId] = state.dismissedTokenWarnings[chainId] ?? {}
      state.dismissedTokenWarnings[chainId][tokenAddress] = true
    })
    .addCase(addSerializedPair, (state, { payload: { serializedPair } }) => {
      if (
        serializedPair.token0.chainId === serializedPair.token1.chainId &&
        serializedPair.token0.address !== serializedPair.token1.address
      ) {
        const chainId = serializedPair.token0.chainId
        state.pairs[chainId] = state.pairs[chainId] || {}
        state.pairs[chainId][pairKey(serializedPair.token0.address, serializedPair.token1.address)] = serializedPair
      }
      state.timestamp = currentTimestamp()
    })
    .addCase(removeSerializedPair, (state, { payload: { chainId, tokenAAddress, tokenBAddress } }) => {
      if (state.pairs[chainId]) {
        // just delete both keys if either exists
        delete state.pairs[chainId][pairKey(tokenAAddress, tokenBAddress)]
        delete state.pairs[chainId][pairKey(tokenBAddress, tokenAAddress)]
      }
      state.timestamp = currentTimestamp()
    })
    .addCase(updateGasPrice, (state, action) => {
      state.gasPrice = action.payload.gasPrice
      state.gasPriceType = action.payload.gasPriceType
    })
    .addCase(updateETHFee, (state, action) => {
      state.ETHFee = action.payload.userETHFee
      state.dexFee =  action.payload.dexFee
      state.tokenAFee =  action.payload.tokenAFee
      state.tokenBFee =  action.payload.tokenBFee
    })
    .addCase(updateGasPricesList, (state, action) => {
      if (state.gasPrices) {
        state.gasPrices[action.payload.chainId] = action.payload.gasPrices
      } else {
        state.gasPrices = {
          ...initialState.gasPrices
        }
        state.gasPrices[action.payload.chainId] = action.payload.gasPrices
      }
    })
)
