import { HashRouter, Route, Switch, Redirect, useLocation } from 'react-router-dom'
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import io from 'socket.io-client'
// import ShowMoreText from "react-show-more-text";
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header, { Title } from '../components/Header'
import Popups from '../components/Popups'
import PageNotFound from './PageNotFound'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'

// import AddLiquidity from './AddLiquidity'
// import {
//   RedirectDuplicateTokenIds,
//   RedirectOldAddLiquidityPathStructure,
//   RedirectToAddLiquidity
// } from './AddLiquidity/redirects'
// import Pool from './Pool'
// import PoolFinder from './PoolFinder'
// import RemoveLiquidity from './RemoveLiquidity'
// import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap, RedirectToSwap2 } from './Swap/redirects'
import { ethers } from 'ethers'
import { useGasPrices, useGasType } from '../state/user/hooks'
import { useDispatch } from 'react-redux'
import { updateGasPrice, updateGasPricesList, updateVersion } from '../state/user/actions'
import axios from 'axios'
import { useActiveWeb3React } from '../hooks'
import { BigNumber } from '@ethersproject/bignumber'
import { ChainId } from '@safemoon/sdk'
import { popupEmitter, PopupTypes, SAVE_LOG_URL, MESSAGE_ALERTS_URL } from '../constants'
import { BlacklistWalletPopup } from '../components/BlacklistWalletPopup'
import { BlacklistTokenPopup } from '../components/BlacklistTokenPopup'
import Bridge from './Bridge'
import Banner from './Swap/Banner'
import { toWei } from '../utils'
import ConfirmModal from '../components/ConfirmModal'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'
// import ManageSTP from './ManageSTP'
// import AddEditSTP from './ManageSTP/AddEditSTP'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`

const BodyWrapper = styled.div<{ viewMode?: any }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 160px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      padding: ${({ viewMode }: any) => (viewMode === 'mobile' ? 0 : '16px')};
  `};

  z-index: 1;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

let gasPriceInterval
export default function App() {
  const gasPrices = useGasPrices()
  const gasType = useGasType()
  const dispatch = useDispatch()
  const { chainId, account } = useActiveWeb3React()
  const [showBlacklistWallet, setShowBlacklistWallet] = useState(false)
  const [showBlacklistToken, setShowBlacklistToken] = useState(false)
  const [messageAlerts, setMessageAlerts] = useState([])
  const [showConfirm, setShowConfirm] = useState<any>()
  const [query, setQuery] = useState<any>({})

  useEffect(() => {
    if (showConfirm) {
      handleConfirm(true, showConfirm) 
    }
  }, [query])

  useEffect(() => {
    const getMessageAlerts = async () => {
      try {
        const result = await axios.get(MESSAGE_ALERTS_URL, {
          params: {}
        })


        const ids = sessionStorage.getItem('DELETE_MESSAGE_IDS')
        const messageIds = ids ? ids.split(',') : []

        const listMessageAlert = []

        result.data.docs?.filter((message) => {
          if (messageIds?.indexOf(message._id) === -1 && (message.alertType === 'SWAP' || message.alertType === 'MAINTENANCE')) {
            listMessageAlert.push(message)
          }
          if (message.alertType === 'MAINTENANCE') {
            setShowConfirm({...message, hideClose: true })
          }
        })

        setMessageAlerts(listMessageAlert)
      } catch(e) {
        console.log(e)
      }
    }

    getMessageAlerts()
  }, [])

  const viewMode = useMemo(() => {
    return window.location.hash?.indexOf('viewMode=mobile') !== -1 ? 'mobile' : ''
  }, [])

  useEffect(() => {
    if (account) {
      axios.post(SAVE_LOG_URL, {
        address: account,
        source: viewMode === 'mobile' ? 'WEB_SWAP_MOBILE' : 'WEB_SWAP'
      })
    }
  }, [account, viewMode])

  const getEthGasPrice = useCallback(async () => {
    try {
      const res = await axios.get(`https://marketdata.safemoon.com/api/swap/gas-price?chainId=1`)
      const ethGasPrices = {
        default: BigNumber.from(res?.data?.result?.SafeGasPrice)
          .mul(10 ** 9)
          .toString(),
        fast: BigNumber.from(res?.data?.result?.ProposeGasPrice)
          .mul(10 ** 9)
          .toString(),
        instant: BigNumber.from(res?.data?.result?.FastGasPrice)
          .mul(10 ** 9)
          .toString(),
        testnet: BigNumber.from(res?.data?.result?.SafeGasPrice)
          .mul(10 ** 9)
          .toString()
      }

      dispatch(updateGasPricesList({ gasPrices: ethGasPrices, chainId: ChainId.MAINNET }))
    } catch (e) {
      console.log(e)
    }
  }, [dispatch])

  const getPolygonGasFee = useCallback(async () => {
    try {
      const res = await axios.get(`https://marketdata.safemoon.com/api/swap/gas-price?chainId=137`)

      const ethGasPrices = {
        default: toWei({
          val: res?.data?.result?.SafeGasPrice,
          decimals: 9
        }),
        fast: toWei({
          val: res?.data?.result?.ProposeGasPrice,
          decimals: 9
        }),
        instant: toWei({
          val: res?.data?.result?.FastGasPrice,
          decimals: 9
        }),
        testnet: toWei({
          val: res?.data?.result?.SafeGasPrice,
          decimals: 9
        })
      }

      dispatch(updateGasPricesList({ gasPrices: ethGasPrices, chainId: ChainId.POLYGON }))
    } catch (e) {
      console.log(e)
    }
  }, [dispatch])

  useEffect(() => {
    const socketIo = io(process.env.REACT_APP_SOCKET_URL, {
      query: {
        type: 'User',
        connectionType: 'PUBLIC',
      },
      path: '/chat-socket',
    })

    // socketIo.on('connect', () => {
    //   console.log('connect =========>', socketIo)
    // })
    // socketIo.on('disconnect', () => {
    //   console.log('disconnect =========>', socketIo)
    // })
    // socketIo.on('error', (e) => {
    //   console.log('error =========>', e)
    // })

    // console.log(process.env.REACT_APP_SOCKET_URL, socketIo)
    
    socketIo.on('gas-price', (data) => {

      // console.log('gas-price data', data)
      if (data.chainId === '137' && (chainId === ChainId.POLYGON || chainId === ChainId.MUMBAI_TESTNET)) {
        // console.log('updatee =====>', data)
        const ethGasPrices = {
          default: toWei({
            val: data?.data?.result?.SafeGasPrice,
            decimals: 9
          }),
          fast: toWei({
            val: data?.data?.result?.ProposeGasPrice,
            decimals: 9
          }),
          instant: toWei({
            val: data?.data?.result?.FastGasPrice,
            decimals: 9
          }),
          testnet: toWei({
            val: data?.data?.result?.SafeGasPrice,
            decimals: 9
          })
        }
  
        dispatch(updateGasPricesList({ gasPrices: ethGasPrices, chainId: ChainId.POLYGON }))
      } else if (data.chainId === '1' && (chainId === ChainId.MAINNET || chainId === ChainId.SEPOLIA_TESTNET)) {
        // console.log('updatee 2=====>', data)
        const ethGasPrices = {
          default: BigNumber.from(data?.data?.result?.SafeGasPrice)
            .mul(10 ** 9)
            .toString(),
          fast: BigNumber.from(data?.data?.result?.ProposeGasPrice)
            .mul(10 ** 9)
            .toString(),
          instant: BigNumber.from(data?.data?.result?.FastGasPrice)
            .mul(10 ** 9)
            .toString(),
          testnet: BigNumber.from(data?.data?.result?.SafeGasPrice)
            .mul(10 ** 9)
            .toString()
        }
  
        dispatch(updateGasPricesList({ gasPrices: ethGasPrices, chainId: ChainId.MAINNET }))
      }
    })

    return () => {
      if (socketIo) {
        socketIo.disconnect()
      }
    }
  }, [chainId])

  const getBscGasPrice = useCallback(async () => {
    try {
      const bscGasPrice = {
        default: BigNumber.from(3)
          .mul(10 ** 9)
          .toString(),
        fast: BigNumber.from(4)
          .mul(10 ** 9)
          .toString(),
        instant: BigNumber.from(5)
          .mul(10 ** 9)
          .toString(),
        testnet: BigNumber.from(10)
          .mul(10 ** 9)
          .toString()
      }

      dispatch(updateGasPricesList({ gasPrices: bscGasPrice, chainId: ChainId.BSC_MAINNET }))
    } catch (e) {
      console.log(e)
    }
  }, [dispatch])

  useEffect(() => {
    getBscGasPrice()
  }, [getBscGasPrice])

  useEffect(() => {
    const showBlacklistWalletPopup = () => {
      setShowBlacklistWallet(true)
    }
    const showBlacklistTokenPopup = () => {
      setShowBlacklistToken(true)
    }
    popupEmitter.on(PopupTypes.BLACKLIST_WALLET, showBlacklistWalletPopup)
    popupEmitter.on(PopupTypes.BLACKLIST_TOKEN, showBlacklistTokenPopup)

    return () => {
      popupEmitter.off(PopupTypes.BLACKLIST_WALLET, showBlacklistWalletPopup)
      popupEmitter.off(PopupTypes.BLACKLIST_TOKEN, showBlacklistTokenPopup)
    }
  }, [setShowBlacklistWallet, setShowBlacklistToken])

  useEffect(() => {
    dispatch(updateVersion())
  }, [dispatch])

  useEffect(() => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
      provider.on('network', (newNetwork, oldNetwork) => {
        if (oldNetwork && window?.location?.hash.indexOf('bridge') === -1) {
          // window.location.reload()
        }
      })
    }
  }, [])

  useEffect(() => {
    dispatch(updateGasPrice({ gasPrice: gasPrices[gasType], gasPriceType: gasType }))
  }, [gasPrices, gasType, chainId, dispatch])

  // useEffect(() => {
  //   getEthGasPrice()
  //   gasPriceInterval = setInterval(getEthGasPrice, 30000)

  //   return () => {
  //     clearInterval(gasPriceInterval)
  //   }
  // }, [getEthGasPrice])

  // useEffect(() => {
  //   getPolygonGasFee()
  //   const polyginGasPriceInterval = setInterval(getPolygonGasFee, 8000)

  //   return () => {
  //     clearInterval(polyginGasPriceInterval)
  //   }
  // }, [getPolygonGasFee])

  const handleConfirm = (dontShowAgain, message) => {
    setMessageAlerts((prev) => (
      prev.filter((item) => item._id !== message._id)
    ))
    if (dontShowAgain) {
      const ids = sessionStorage.getItem('DELETE_MESSAGE_IDS')
      const messageIds = ids ? ids.split(',') : []
      sessionStorage.setItem('DELETE_MESSAGE_IDS', [...messageIds, message._id].join(','))
    }
    setShowConfirm(null)
  }

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <Route component={GoogleAnalyticsReporter} />
        <Route component={DarkModeQueryParamReader} />
        <AppWrapper>
          
          <div id='messageAlerts' className='z-[99] relative w-full'>
            {messageAlerts && messageAlerts?.length > 0
              && <div className='flex flex-col items-center w-full'>
                {messageAlerts?.map((message, i) => (
                  <div className='alert-wraper flex items-center lg:justify-center' key={message._id}
                    style={i === messageAlerts?.length - 1 ? {marginBottom: 0} : {}}
                  >
                    <div className='alert-text lg:text-center' key={message._id} dangerouslySetInnerHTML={{__html: message.shortMessage}}/>
                      {message.buttonText
                        && <a className='cursor-pointer btnLearnMore'
                        onClick={(e) => {
                          if (message.actionType === 'OPEN_POPUP') {
                            e.preventDefault()
                            e.stopPropagation()
                            setShowConfirm(message)
                          }
                          
                        }}
                        href={message.url}
                        target={message.actionType === 'OPEN_LINK_NEW_TAB' ? '_blank' : '_self'}
                      >
                        {message.buttonText || 'Learn More'}
                      </a>
                      }
                      
                      {message.alertType !== 'MAINTENANCE'
                        && <a className='cursor-pointer btnClose'
                        onClick={() => {
                          handleConfirm(true, message)
                        }}
                      >
                        <img src={'/images/close-white.svg'} className='w-[20px]'/>
                      </a>
                      }
                      
                    
                   
                  </div>
                ))}
              </div>
            }
            </div>
            {!(showConfirm && showConfirm.alertType === 'MAINTENANCE')
              ? <HeaderWrapper>
                <Header numberMessages={messageAlerts && messageAlerts?.length > 0 ? messageAlerts?.length : 0}
                  setQuery={setQuery}
                />
              </HeaderWrapper> : <Title href={query?.viewMode !== 'mobile' ? 'https://safemoon.com/' : undefined} target="_blank"
                className='z-[3] px-[20px] lg:px-[80px] pt-[10px] lg:pt-[16px]'
              >
                <img
                  src={isMobile ? '/logo.png' : '/images/swap-logo.svg'}
                  className={classNames(isMobile ? 'w-[48px]' : 'w-[240px]')}
                  alt="logo"
                  style={{
                    width: isMobile ? '48px' : '240px'
                  }}
                />
              </Title>
            }
         
          <Popups />
          <BodyWrapper viewMode={viewMode}>
            <Banner />
            <BlacklistWalletPopup open={showBlacklistWallet} onDismiss={() => setShowBlacklistWallet(false)} />
            <BlacklistTokenPopup open={showBlacklistToken} onDismiss={() => setShowBlacklistToken(false)} />
            
            <ConfirmModal show={!!showConfirm}
              handleClose={() => {
                handleConfirm(true, showConfirm)
                setShowConfirm(null)
              }}
              handleConfirm={handleConfirm}
              message={showConfirm}
            />
            
            
            <Web3ReactManager>
             {!(showConfirm && showConfirm.alertType === 'MAINTENANCE') &&
              <Switch>
                <Route exact strict path="/" render={() => <Redirect to="/swap" />} />
                <Route exact strict path="/swap" component={Swap} />
                <Route exact strict path="/swap/:currencyIdA/:currencyIdB" component={RedirectToSwap2} />
                <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
                <Route exact strict path="/bridge" component={Bridge} />
                {/* <Route exact strict path="/find" component={PoolFinder} />
                <Route exact strict path="/pool" component={Pool} />

                <Route exact strict path="/create" component={RedirectToAddLiquidity} />
                <Route exact path="/add" component={AddLiquidity} />
                <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} /> */}
                <Route component={PageNotFound} />
              </Switch>
             }
            </Web3ReactManager>
            <Marginer />
          </BodyWrapper>
        </AppWrapper>
      </HashRouter>
    </Suspense>
  )
}
