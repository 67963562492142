import { createAction } from '@reduxjs/toolkit'
import { TokenList } from '@uniswap/token-lists'

export type PopupContent =
  | {
      txn: {
        hash: string
        success: boolean
        summary?: string
      }
    }
  | {
      listUpdate: {
        listUrl: string
        oldList: TokenList
        newList: TokenList
        auto: boolean
      }
    }

export const updateBlockNumber = createAction<{ chainId: number; blockNumber: number }>('updateBlockNumber')
export const toggleWalletModal = createAction<{ chainId: number }>('toggleWalletModal')
export const setWalletModalChainId = createAction<{ chainId: number }>('setWalletModalChainId')
export const toggleSettingsMenu = createAction<void>('toggleSettingsMenu')
export const addPopup = createAction<{ key?: string; content: PopupContent }>('addPopup')
export const removePopup = createAction<{ key: string }>('removePopup')
export const toggleWrongNetwork = createAction<void>('toggleWrongNetwork')
export const addModal = createAction<{ id: number }>('addModal')
export const removeModal = createAction<{ id: number }>('removeModal')
