import { Currency, Pair, WETH } from '@safemoon/sdk'
import React, { useState, useContext, useCallback, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { darken } from 'polished'
import Dotdotdot from 'react-dotdotdot'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween } from '../Row'
import { TYPE } from '../../theme'
import { Input as NumericalInput } from '../NumericalInput'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'

import { useActiveWeb3React } from '../../hooks'
import { useTranslation } from 'react-i18next'
import getTokenSymbol from '../../utils/getTokenSymbol'
import { convertObjectToSearchParams, formatStringToPrice, isStableCoin, routingNumber } from '../../utils'
import { useHistory } from 'react-router'
import useParsedQueryString from '../../hooks/useParsedQueryString'

const InputRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: 5px;
  border: 0;
  height: 50px;
  border-radius: 30px;
  margin-top: 8px;
  background-color: ${({ theme }) => theme.bgDark};
`

const InputContainer = styled.div`
  padding: 2px 2px 2px 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // max-width: 244px;
`

const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  background-color: ${({ theme }) => theme.bgMain};
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
  border-radius: 20px;
  box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0 0.5rem;
  min-width: 160px;
  transition: 0.3s ease all;

  :focus,
  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 115px;
  `};
`

const CurrencyInput = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
`

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text3};
  font-size: 0.875rem;
  line-height: 1rem;
  height: 24px;

  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
    stroke-width: 1.5px;
  }
`

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  z-index: 1;
`

const Container = styled.div<{ hideInput: boolean }>``

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.75rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size: 16px;
  font-weight: 700;
`

const StyledBalanceMax = styled.button`
  height: 40px;
  background-color: ${({ theme }) => theme.bgMain};
  border-radius: 20px;
  font-size: 0.875rem;
  padding: 0.5rem 1.5rem;
  font-weight: 500;

  cursor: pointer;
  color: ${({ theme }) => theme.text1};
  transition: 0.3s ease all;

  :hover {
    background-color: ${({ theme }) => theme.bg4};
  }
  :focus {
    background-color: ${({ theme }) => theme.bg2};
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0.25rem 1rem;
    margin-left: 0;
  `};
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  showSendWithSwap?: boolean
  otherCurrency?: Currency | null
  id: string
  priceUsd?: any
  showBalance?: any
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label = 'Input',
  onCurrencySelect = null,
  currency = null,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  showSendWithSwap = false,
  otherCurrency = null,
  id,
  priceUsd = {},
  showBalance
}: CurrencyInputPanelProps) {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account, currency)
  const theme = useContext(ThemeContext)

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  // console.log('priceUsd ===>', priceUsd)

  const tokenPriceUsd = useMemo(() => {
    const tokenCurrency: any = currency
    let address = tokenCurrency?.address
    if (tokenCurrency?.symbol === 'ETH' && !tokenCurrency.address) {
      if (chainId === 56) {
        address = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
      } else if (chainId === 1) {
        address = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
      } else if (chainId === 137) {
        address = WETH[137].address
      }
    } else if (tokenCurrency?.symbol === 'ETH' && tokenCurrency.address && chainId === 56) {
      address = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
    }

    return priceUsd[address?.toLowerCase()]
  }, [priceUsd, chainId, currency])

  const tokenValueUsd = useMemo(() => {
    return tokenPriceUsd * +value
  }, [tokenPriceUsd, value])

  const history = useHistory()
  const query = useParsedQueryString()

  return (
    <InputPanel id={id}>
      <Container hideInput={hideInput}>
        {!hideInput && (
          <LabelRow>
            <RowBetween>
              <TYPE.body color={theme.textLabel} fontWeight={400} fontSize={16}>
                {label}
              </TYPE.body>
              {account && (
                <TYPE.body
                  onClick={() => {
                    history.push(
                      `/swap${convertObjectToSearchParams({
                        ...query,
                        showBalance: showBalance === 'true' ? 'false' : 'true'
                      })}`
                    )
                  }}
                  color={theme.textLabel}
                  fontWeight={400}
                  fontSize={16}
                  style={{ display: 'inline', cursor: 'pointer' }}
                >
                  {!hideBalance && !!currency && selectedCurrencyBalance
                    ? `Balance: ${showBalance === 'false' ? '****' : selectedCurrencyBalance?.toSignificant(6)}`
                    : ' -'}
                </TYPE.body>
              )}
            </RowBetween>
          </LabelRow>
        )}
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}}>
          <CurrencySelect
            selected={!!currency}
            className="open-currency-select-button"
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Aligner>
              <CurrencyInput>
                {pair ? (
                  <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
                ) : currency ? (
                  <CurrencyLogo currency={currency} size={'24px'} />
                ) : null}
                {pair ? (
                  <StyledTokenName className="pair-name-container">
                    {getTokenSymbol(pair?.token0, chainId)}:{getTokenSymbol(pair?.token1, chainId)}
                  </StyledTokenName>
                ) : (
                  <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                    {(currency && currency.symbol && currency.symbol.length > 20
                      ? currency.symbol.slice(0, 4) +
                        '...' +
                        currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                      : getTokenSymbol(currency, chainId)) || t('selectToken')}
                  </StyledTokenName>
                )}
              </CurrencyInput>
              {!disableCurrencySelect && <StyledDropDown selected={!!currency} />}
            </Aligner>
          </CurrencySelect>
          {!hideInput && (
            <InputContainer>
              <NumericalInput
                className="token-amount-input text-right mr-[10px]"
                value={value}
                onUserInput={val => {
                  onUserInput(val)
                }}
              />
              {account && currency && showMaxButton && label !== 'To' && (
                <StyledBalanceMax onClick={onMax}>Max</StyledBalanceMax>
              )}
            </InputContainer>
          )}
        </InputRow>
        <div className="flex items-center justify-between">
          {tokenPriceUsd && !isStableCoin(currency?.symbol) ? (
            <TYPE.body color={theme.textLabel} fontWeight={400} fontSize={16} mt={'10px'} lineHeight={'18px'}>
              Price: ${tokenPriceUsd > 1 ? tokenPriceUsd : routingNumber(tokenPriceUsd, 20)}
            </TYPE.body>
          ) : (
            <TYPE.body color={theme.textLabel} fontWeight={400} fontSize={16} mt={'10px'} lineHeight={'18px'}>
              &nbsp;
            </TYPE.body>
          )}

          {!isStableCoin(currency?.symbol) && tokenValueUsd > 0 && (
            <div className="flex-1 pl-[16px]">
              <Dotdotdot clamp={1} truncationChar="...+">
                <TYPE.body
                  color={theme.textLabel}
                  fontWeight={400}
                  fontSize={16}
                  mt={'10px'}
                  lineHeight={'18px'}
                  textAlign={'right'}
                >
                  <b>&#8764;</b> ${formatStringToPrice(tokenValueUsd)}
                </TYPE.body>
              </Dotdotdot>
            </div>
          )}
        </div>
      </Container>
      {!disableCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          showSendWithSwap={showSendWithSwap}
          hiddenCurrency={currency}
          otherSelectedCurrency={otherCurrency}
        />
      )}
    </InputPanel>
  )
}
