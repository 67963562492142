/* eslint-disable */
import React from 'react'
import Modal from '../Modal'
import './ConfirmModal.css'

interface Props {
  show: boolean
  handleClose: any,
  handleConfirm: any,
  message: any
}

const ConfirmModal = ({ show, handleClose, handleConfirm, message }: Props) => {
  
  return (
    <Modal
      isOpen={show}
      onDismiss={message?.alertType !== 'MAINTENANCE' && handleClose}
      forceMaxHeight={'70vh'}
      maxHeight={70}
    >
      
      <div className="slippage-warning">
        {message?.alertType !== 'MAINTENANCE'
          && <a className='cursor-pointer absolute right-[20px] top-[10px]'
            onClick={handleClose}
          >
            <img src={'/images/close-white.svg'} className='close-icon'/>
          </a>
        }
        
        <div className="slippage-warning-text" dangerouslySetInnerHTML={{__html: message?.message}} />
        
        {/* <div className='action-center'>
          <a
            className="btn btnMain"
            onClick={() => {
              handleConfirm(false)
            }}
          >
            Confirm
          </a>
        </div>
       
        <a className={'btn-link'}
          onClick={() => {
            handleConfirm(true)
          }}
        >
          Don't show message again
        </a> */}
      </div>
    </Modal>
  )
}

export default ConfirmModal
