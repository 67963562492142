/* eslint-disable */
import React from 'react'
import closeIcon from '../../../assets/images/close.svg'
import Modal from '../../../components/Modal'
import './ConsolidateV2Intro.css'

interface Props {
  show: boolean
  handleClose: any
  handleConvertV1ToV2: any
}

const ConsolidateV2Intro = ({ show, handleClose, handleConvertV1ToV2 }: Props) => {
  return (
    <Modal  isOpen={show}
      onDismiss={handleClose}
      // alignSelf="flex-end"
      forceMaxHeight={'70vh'}
      maxHeight={70}
      >
      <a
        className={'btn-close'}
        onClick={() => {
          handleClose()
        }}
      >
        <img className={'close-icon'} src={closeIcon} alt="closeIcon" />
      </a>
      <div className="consolidate-intro mt-[40px]">
        <h2 className="consolidate-intro-title mb-[20px]">Consolidate your SafeMoon V1 to SFM V2</h2>
        <p className="consolidate-intro-description">
          If you still hold a balance of SafeMoon V1, you are eligible to migrate and consolidate your tokens to SFM V2 at a rate of 1000:1
          <span className='block h10'/>
          For every 1000 V1 tokens you migrate, you will receive 1 SFM V2.
          <span className='block h10'/>
          To migrate now select "Consolidate to V2 SafeMoon".
          <span className='block h10'/>
          If your balance is not displayed and you believe your connected wallet is eligible for migration, you may choose “Migration Completion Program” in the dropdown menu for more options to assist you.
        </p>
        

        <a
          className="btn btnMain"
          onClick={() => {
            handleConvertV1ToV2()
            handleClose()
          }}
        >
          Consolidate to V2 SafeMoon
        </a>
      </div>
    </Modal>
  )
}

export default ConsolidateV2Intro
