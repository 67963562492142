import { useCallback, useEffect, useMemo } from 'react'
import { useActiveWeb3React } from '../../hooks'
import {
  addPopup,
  PopupContent,
  removePopup,
  toggleWalletModal,
  toggleSettingsMenu,
  setWalletModalChainId,
  toggleWrongNetwork
} from './actions'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../index'
import { ModalI } from './reducer'

export function useBlockNumber(): number | undefined {
  const { chainId } = useActiveWeb3React()

  return useSelector((state: AppState) => state.application.blockNumber[chainId ?? -1])
}

export function useWrongNetwork() {
  const showWrongNetwork = useSelector((state: AppState) => state.application.showWrongNetwork)
  const dispatch = useDispatch()

  const toggleShowWrongNetwork = () => {
    dispatch(toggleWrongNetwork())
  }

  return { showWrongNetwork, toggleWrongNetwork: toggleShowWrongNetwork }
}

export function useWalletModalOpen(): boolean {
  return useSelector((state: AppState) => state.application.walletModalOpen)
}

export function useGetModals(): ModalI[] {
  return useSelector((state: AppState) => state.application.modals)
}

export function useWalletModalChainId(currentChainId: number): [number | undefined, (chainId: number) => void] {
  const openModalChainId = useSelector((state: AppState) => state.application.openModalChainId)
  const dispatch = useDispatch()
  const setChainId = (chainId: number) => {
    dispatch(setWalletModalChainId({ chainId }))
  }

  useEffect(() => {
    if (currentChainId) {
      setChainId(currentChainId)
    }
  }, [currentChainId])
  return [openModalChainId, setChainId]
}

export function useWalletModalToggle(): (chainId?: any) => void {
  const dispatch = useDispatch()
  return useCallback(
    chainId => dispatch(toggleWalletModal({ chainId: chainId && !isNaN(chainId) ? chainId : undefined })),
    [dispatch]
  )
}

export function useSettingsMenuOpen(): boolean {
  return useSelector((state: AppState) => state.application.settingsMenuOpen)
}

export function useToggleSettingsMenu(): () => void {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(toggleSettingsMenu()), [dispatch])
}

// returns a function that allows adding a popup
export function useAddPopup(): (content: PopupContent, key?: string) => void {
  const dispatch = useDispatch()

  return useCallback(
    (content: PopupContent, key?: string) => {
      dispatch(addPopup({ content, key }))
    },
    [dispatch]
  )
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
  const dispatch = useDispatch()
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }))
    },
    [dispatch]
  )
}

// get the list of active popups
export function useActivePopups(): AppState['application']['popupList'] {
  const list = useSelector((state: AppState) => state.application.popupList)
  return useMemo(() => list.filter(item => item.show), [list])
}
