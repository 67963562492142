import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { animated, useTransition } from 'react-spring'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { isMobile } from 'react-device-detect'
import '@reach/dialog/styles.css'
// import { useGesture } from 'react-use-gesture'
import BodyBG from '../../assets/images/body-bg.jpg'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { useGetModals } from '../../state/application/hooks'
import { useDispatch } from 'react-redux'
import { addModal, removeModal } from '../../state/application/actions'

const AnimatedDialogOverlay = animated(DialogOverlay)
// eslint-disable-next-line @typescript-eslint/no-unused-vars

const StyledDialogOverlay = styled(AnimatedDialogOverlay)<{ viewMode?: any, alertMessageHeight?: number }>`
  &[data-reach-dialog-overlay] {
    z-index: 2;
    overflow: auto;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    background-position: center center;
    background-repeat: no-repeat;
    // background-image: url(${BodyBG});
    background-size: cover;
    background-attachment: fixed;

    flex-direction: column;
    padding-top: ${({ alertMessageHeight }: any) => `${160 + alertMessageHeight}px`};

    ${({ theme }) => theme.mediaWidth.upToMedium`
      // position: absolute;
      padding-top: ${({ viewMode, alertMessageHeight }: any) => (viewMode === 'mobile' ? `${116 + alertMessageHeight}px` : `${152 + alertMessageHeight}px`)};
      // justify-content: ${({ viewMode }: any) => (viewMode === 'mobile' ? 'center' : 'flex-start')};
    `}
  }

`

const StyledDialogOverlayDiv = styled.div<{ viewMode?: any, alertMessageHeight?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  overflow: auto;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  background-position: center center;
  background-repeat: no-repeat;
  // background-image: url(${BodyBG});
  background-size: cover;
  background-attachment: fixed;

  flex-direction: column;
  padding-top: ${({ alertMessageHeight }: any) => `${160 + alertMessageHeight}px`};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    // position: absolute;
    padding-top: ${({ viewMode, alertMessageHeight }: any) => (viewMode === 'mobile' ? `${116 + alertMessageHeight}px` : `${152 + alertMessageHeight}px`)};
    // justify-content: ${({ viewMode }: any) => (viewMode === 'mobile' ? 'center' : 'flex-start')};
  `}

`

const AnimatedDialogContent = animated(DialogContent)
// destructure to not pass custom props to Dialog DOM element
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledDialogContent = styled(({ minHeight, maxHeight, mobile, forceMaxHeight, alertMessageHeight, isOpen, alignSelf, ...rest }) => (
  <AnimatedDialogContent {...rest} />
)).attrs({
  'aria-label': 'dialog'
})`
  &[data-reach-dialog-content] {
    margin: 0 0 2rem 0;
    border: 1px solid ${({ theme }) => theme.bgMain};
    background-color: ${({ theme }) => theme.bgMain};
    padding: 0px;
    width: 50vw;
    position: relative;
    z-index: 2;

    align-self: ${({ mobile, alignSelf }) => (mobile ? alignSelf || 'center' : 'center')};

    max-width: 451px;
    ${({ maxHeight, mobile, forceMaxHeight, alertMessageHeight }) =>
      maxHeight &&
      css`
        max-height: ${mobile ? forceMaxHeight ? `calc(${forceMaxHeight} - ${alertMessageHeight}px)` : 'auto' : `calc(${maxHeight}vh - ${alertMessageHeight}px)`};
      `}
    ${({ minHeight }) =>
      minHeight &&
      css`
        min-height: ${minHeight}vh;
      `}
    display: flex;
    border-radius: 10px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 65vw;
      margin: 0;
    `}
    ${({ theme, mobile, alignSelf }) => theme.mediaWidth.upToSmall`
      width:  85vw;
      ${mobile &&
        css`
          width: ${alignSelf !== 'flex-end' ? 'calc(100vw - 16px)' : '100vw'};
          border-radius: 10px;
          max-height: 100%;
          overflow-y: auto;
        `}
    `}
  }
`

interface ModalProps {
  isOpen: boolean
  onDismiss: () => void
  minHeight?: number | false
  maxHeight?: number
  initialFocusRef?: React.RefObject<any>
  children?: React.ReactNode
  forceMaxHeight?: string
  alignSelf?: string
  showDivOverlay?: boolean
}

export default function Modal({
  isOpen,
  onDismiss,
  minHeight = false,
  maxHeight = 50,
  initialFocusRef = null,
  children,
  forceMaxHeight,
  alignSelf,
  showDivOverlay = false
}: ModalProps) {
  const [modalId, setModalId] = useState<number>(0)
  const query = useParsedQueryString()
  const [alertMessageHeight, setAlertMessageHeight] = useState(0)

  const fadeTransition = useTransition(isOpen, null, {
    config: { duration: 200 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  const modals = useGetModals()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setAlertMessageHeight(document.getElementById('messageAlerts')?.clientHeight || 0)
      }, 100)
    
    }
  }, [isOpen])

  useEffect(() => {
    const element = document.getElementById('bg-video')
    if (isOpen) {
      const id = new Date().valueOf()
      dispatch(
        addModal({
          id
        })
      )
      setModalId(id)
      if (element) {
        element.style.zIndex = '99'
      }
      window.scrollTo(0, 0)
      return () => {
        dispatch(
          removeModal({
            id: id
          })
        )
      }
    }
  }, [isOpen, dispatch])

  useEffect(() => {
    if (!isOpen) {
      dispatch(
        removeModal({
          id: modalId
        })
      )
    }
  }, [isOpen, dispatch, modalId])

  const show = useMemo(() => {
    return modals && modals?.length > 0 && modalId === modals[0]?.id
  }, [modals, modalId])

  const OverlayComponent = showDivOverlay ? StyledDialogOverlayDiv : StyledDialogOverlay

  return (
    show && (
      <>
        {fadeTransition.map(
          ({ item, key, props }) =>
            item && (
              <OverlayComponent
                key={key}
                style={props}
                onDismiss={onDismiss}
                initialFocusRef={initialFocusRef}
                viewMode={query?.viewMode}
                alertMessageHeight={alertMessageHeight}
              >
                <StyledDialogContent
                  {...(isMobile
                    ? {
                        // ...bind(),
                        // style: { transform: y.interpolate(y => `translateY(${y > 0 ? y : 0}px)`) }
                      }
                    : {})}
                  aria-label="dialog content"
                  minHeight={minHeight}
                  maxHeight={maxHeight}
                  mobile={isMobile}
                  forceMaxHeight={forceMaxHeight}
                  alignSelf={alignSelf}
                  alertMessageHeight={alertMessageHeight}
                >
                  {/* prevents the automatic focusing of inputs on mobile by the reach dialog */}
                  {!initialFocusRef && isMobile ? <div tabIndex={1} /> : null}
                  {children}
                </StyledDialogContent>
              </OverlayComponent>
            )
        )}
      </>
    )
  )
}
